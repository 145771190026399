import React from 'react';
import './css/element.css';
import './css/viewer.css';
import axios from 'axios';
import Draggable from 'react-draggable';

import domtoimage from 'dom-to-image';
import download from 'downloadjs';

import ViewElement from './viewer/editor';
import ViewImage from './viewer/image';
import ViewTable from './viewer/table';
import ViewSignee from './viewer/signee';
import ViewQR from './viewer/qr';

import Warning from '../components/warning';
import WarningAction from '../components/warning_action';

import jsPDF from 'jspdf';
import ProgressLoader from '../components/progress_loader';

class CertificateViewerComponent extends React.Component {
    constructor(props) {
        super(props);
        this.fetchWarningState.bind(this);
        this.state = {
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            warning_action: {
                message: "",
                subtext: "",
                type: "warning",
                proceed_action: false,
                proceed_function: false,
                back_action: false,
                close: true,
            },
            QRSize: 65,
            sliderValue: 3,
            sliderClass: "slider-3",
            pinchStartDistance: null,
            initialSliderValue: 1,
            elements: [],
            certView: false,
            view_type: '',
            resetX: 0,
            resetY: 0,
            scaleWidth: 1,
            scaleFactor: 1,
            moreBtn: 'more',
            url: new URL(window.location.href).hostname + `/certificate/${this.props.documentId}`,
            title: '',
            description: '',
            pendingUpdateRequest: false,
            rating: 0,
            feedback: false,
            feedback_anonymous: false,
            feedback_text: '',
            feedback_warning: false,
            cacheTs: Date.now(),
            progress_loader: false,
            progress_opaque: true,
            percentage_progress: 0,
            shareModal: false,
            nameChangeModal: true,
            popupAcceptModal: false,
            timeoutID: null,
            hideFixedControls: false,
            moreInfo: false,
            downloadModal: false,
            format: 'image', // default format
            quality: 'medium', // default quality
        };
        this.handleShare = this.handleShare.bind(this);
        this.imageDivRef = React.createRef();
        this.certDivRef = React.createRef();
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    fetchWarningActionState = (data) => {
        if (data === '') {
            this.setState({
                warning_action: {
                    message: data,
                }
            })
        }
        switch (data) {
            case 'popupAcceptModal':
                this.setState({
                    warning_action: {
                        message: "",
                    },
                    popupAcceptModal: true,
                })
                break;
            default:
                return null;
        }
    }

    componentDidMount() {
        this.setState({
            progress_loader: true,
            progress_opaque: true,
            percentage_progress: 10,
        })
        axios.post(this.props.node_server_axios + 'get_certificate', {
            params: {
                email: this.props.email,
                documentId: this.props.documentId,
            },
        }).then(async (res) => {
            this.setState({
                percentage_progress: 30,
            });
            if (res.data !== undefined) {
                if (res.data.message === 'certificate found') {

                    // Redirect if not owner
                    //Old Condition: JSON.parse(res.data.csvData)[0]['[Email]'] !== this.props.email 
                    if (!res.data.own_certificate && this.props.email !== res.data.contact) {
                        window.location = "/certificate/" + this.props.documentId;
                    }

                    var myElement = document.querySelector('.cropper-rel');
                    var width = myElement.offsetHeight > myElement.offsetWidth ? myElement.offsetWidth : myElement.offsetHeight;

                    const updatedData = JSON.parse(res.data.elements);
                    let tableVariables = []
                    if (updatedData.filter(element => element.type === "table").length > 0) {
                        tableVariables = updatedData.filter(element => element.type === "table")[0].content.join("|").match(/\[.*?\]/g)
                    }
                    let variableIndexElements = updatedData.filter(element => element.type !== "image" && element.type !== "signature" && element.type !== "QR" && element.content.includes("[")).map(element => ({ type: element.type, variables: element.content.match(/\[.*?\]/g) !== null ? element.content.match(/\[.*?\]/g).concat(tableVariables) : element.content.match(/\[.*?\]/g) }))
                    let variablesLocal = variableIndexElements.map(element => element.variables)
                    let mergedVariablesLocal = [].concat.apply([], variablesLocal);
                    let uniqueVariablesLocal = mergedVariablesLocal.filter((item, i, ar) => ar.indexOf(item) === i);
                    uniqueVariablesLocal = uniqueVariablesLocal.filter(element => element !== null && element !== "" && element !== undefined);

                    let pendingUpdateRequest = false;
                    await axios.post(this.props.node_server_axios + 'get_certificate_update_request_status', {
                        params: {
                            email: this.props.email,
                            org_id: res.data.org_id,
                            document_id: this.props.documentId,
                        },
                    }).then(async (res) => {
                        if (res.data !== undefined) {
                            if (res.data.message === 'success') {
                                pendingUpdateRequest = true;
                            }
                        }
                    })

                    this.setState({
                        elements: updatedData,
                        variables: uniqueVariablesLocal,
                        org_id: res.data.org_id,
                        cert_bg: res.data.cert_bg,
                        csvData: JSON.parse(res.data.csvData),
                        certView: true,
                        accepted: res.data.accepted,
                        view_type: res.data.view_type,
                        event_name: res.data.event_name,
                        issue_date: res.data.issue_date,
                        pronetin_verified: res.data.pronetin_verified,
                        signees: res.data.signees,
                        org_name: res.data.org_name,
                        cert_title: res.data.cert_title,
                        cert_attachment: res.data.cert_attachment,
                        domain: res.data.domain,
                        contact: res.data.contact,
                        title: res.data.cert_title,
                        description: 'Document received for ' + res.data.event_name + ' on ' + res.data.issue_date,
                        pendingUpdateRequest: pendingUpdateRequest,
                        feedback: res.data.feedback,
                        feedback_anonymous: res.data.anonymous,
                        batch_id: res.data.batch_id,
                        withdrawn: res.data.withdrawn,
                        percentage_progress: 60,
                    }, () => {
                        this.props.documentLoaded(true);
                    })

                    if (res.data.feedback === false && res.data.accepted !== 'true') {
                        const timeoutID = setTimeout(() => {
                            this.setState({
                                popupAcceptModal: true,
                            });
                        }, 5000);

                        this.setState({ timeoutID });
                    }

                    const img = new Image();
                    const imgPromise = new Promise((resolve, reject) => {
                        img.onload = resolve;
                        img.onerror = reject;
                    });
                    img.src = "https://pronetin-new.s3.amazonaws.com/batch/" + encodeURIComponent(res.data.org_id) + "/" + encodeURIComponent(res.data.cert_bg);

                    try {
                        await imgPromise;
                        let cWidth = 904;
                        let cHeight = 640;

                        if (img.naturalWidth > img.naturalHeight) {
                            cWidth = 904;
                            cHeight = 640;
                        } else if (img.naturalWidth < img.naturalHeight) {
                            cWidth = 640;
                            cHeight = 904;
                        } else {
                            console.log("Invalid Size");
                        }
                        this.setState({
                            certWidth: cWidth,
                            certHeight: cHeight,
                            withdrawn_type: cWidth > cHeight ? 'wit0.png' : 'wit0p.png',
                            scaleWidth: ((width - 20) / cHeight).toFixed(2),
                            scaleFactor: myElement.offsetHeight > myElement.offsetWidth ? ((width - 20) / cWidth).toFixed(2) : ((width - 20) / cHeight).toFixed(2),
                            percentage_progress: 90,
                        })
                    } catch (error) {
                        console.log("Error loading image:", error);
                    }
                } else {
                    window.location = '/notfound';
                }
            }
        })
    }

    completedLoad = () => {
        this.setState({
            progress_loader: false,
            percentage_progress: false,
        })
    }

    componentDidUpdate = () => {
        if (this.state.initialX === undefined || this.state.initialY === undefined) {
            let imgDivWidth = 0;
            let imgDivHeight = 0;
            let certDivWidth = 0;
            let certDivHeight = 0;
            if (this.imageDivRef.current) {
                imgDivWidth = this.imageDivRef.current.clientWidth;
                imgDivHeight = this.imageDivRef.current.clientHeight;
            }
            if (this.certDivRef.current) {
                certDivWidth = parseInt(this.certDivRef.current.clientWidth * this.state.scaleFactor);
                certDivHeight = parseInt(this.certDivRef.current.clientHeight * this.state.scaleFactor);
            }
            let initialX = (imgDivWidth - certDivWidth) < 0 ? 0 : (imgDivWidth - certDivWidth)
            let initialY = (imgDivHeight - certDivHeight) < 0 ? 0 : (imgDivHeight - certDivHeight)
            if (this.imageDivRef.current && this.certDivRef.current && this.state.scaleFactor !== 1) {
                this.setState({
                    initialX: initialX / 2,
                    initialY: initialY / 2,
                    resetX: initialX / 2,
                    resetY: initialY / 2,
                })
            }
        }
    }

    viewAttachment = (event) => {
        event.preventDefault();
        window.open("https://pronetin-new.s3.amazonaws.com/attachments/" + encodeURIComponent(this.state.org_id) + "/" + encodeURIComponent(this.state.cert_attachment));
    }

    // checkEligibility = () => {
    //     if (this.props.user_type !== "organisation") {
    //         this.setState({
    //             warning: {
    //                 message: "Sorry, You need an organisation account to issue certificates.",
    //                 type: "warning",
    //                 learnmore: "create",
    //                 autoclose: false,
    //             }
    //         })
    //     } else {
    //         window.location = "/issue";
    //     }
    // }

    updateZoom = (newSliderValue) => {
        this.setState({
            sliderValue: newSliderValue,
            sliderClass: "slider-" + newSliderValue
        })
    }

    updateZoomInput = (e) => {
        this.setState({
            sliderValue: e.target.value,
            sliderClass: "slider-" + e.target.value
        })
    }

    handlePinchStart = (e) => {
        if (e.touches.length === 2) {
            const [touch1, touch2] = e.touches;
            const distance = Math.hypot(
                touch2.clientX - touch1.clientX,
                touch2.clientY - touch1.clientY
            );

            this.setState({
                pinchStartDistance: distance,
                initialSliderValue: this.state.sliderValue,
            });
        }
    };

    handlePinchMove = (e) => {
        if (e.touches.length === 2) {
            const [touch1, touch2] = e.touches;
            const distance = Math.hypot(
                touch2.clientX - touch1.clientX,
                touch2.clientY - touch1.clientY
            );

            const zoomFactor = distance / this.state.pinchStartDistance;
            const newSliderValue = parseInt(this.state.initialSliderValue * zoomFactor);

            // Limit the zoom to a specific range (e.g., between 1 and 7)
            if (newSliderValue >= 1 && newSliderValue <= 7) {
                this.updateZoom(newSliderValue);
            }
        }
    };

    handlePinchEnd = () => {
        this.setState({
            pinchStartDistance: null,
        });
    };

    downloadCertificateMed = async (event) => {
        event.preventDefault();
        this.setState({
            resetX: 0,
            resetY: 0,
            progress_loader: true,
            progress_opaque: true,
            cacheTs: Date.now(),
        })
        const node = document.getElementById('capture');

        // Calculate total width and height of the element
        const elementWidth = node.offsetWidth;
        const elementHeight = node.offsetHeight;

        // Scroll the element into view
        node.scrollIntoView();

        // Adjust the size of the captured element
        const originalWidth = node.style.width;
        const originalHeight = node.style.height;
        node.style.width = elementWidth + 'px';
        node.style.height = elementHeight + 'px';

        // Capture the screenshot
        domtoimage
            .toPng(node)
            .then(async (dataUrl) => {
                await download(dataUrl, 'certificate.png');
                // Restore original size
                node.style.width = originalWidth;
                node.style.height = originalHeight;
                this.setState({
                    progress_loader: false,
                    resetX: this.state.initialX,
                    resetY: this.state.initialY,
                })
            })
            .catch((error) => {
                console.error('Error occurred while trying to download the image', error);
                // Restore original size in case of error
                node.style.width = originalWidth;
                node.style.height = originalHeight;
                this.setState({
                    progress_loader: false,
                    resetX: this.state.initialX,
                    resetY: this.state.initialY,
                })
            });
    };

    downloadCertificatePdfMed = async (event) => {
        event.preventDefault();
        this.setState({
            resetX: 0,
            resetY: 0,
            progress_loader: true,
            progress_opaque: true,
            cacheTs: Date.now(),
        })
        const node = document.getElementById('capture');

        // Calculate total width and height of the element
        const elementWidth = node.offsetWidth;
        const elementHeight = node.offsetHeight;

        // Scroll the element into view
        node.scrollIntoView();

        // Adjust the size of the captured element
        const originalWidth = node.style.width;
        const originalHeight = node.style.height;
        node.style.width = elementWidth + 'px';
        node.style.height = elementHeight + 'px';

        // Capture the screenshot
        domtoimage
            .toPng(node)
            .then(async (dataUrl) => {
                const imgData = dataUrl.split(',')[1]; // Remove data URL header
                // Calculate the aspect ratio
                const aspectRatio = elementWidth / elementHeight;
                // Determine orientation based on aspect ratio
                const orientation = aspectRatio > 1 ? 'landscape' : 'portrait';
                let pdfWidth, pdfHeight;
                if (orientation === 'landscape') {
                    pdfWidth = 297; // A4 paper width in mm
                    pdfHeight = (pdfWidth * elementHeight) / elementWidth;
                } else {
                    pdfHeight = 297; // A4 paper height in mm
                    pdfWidth = (pdfHeight * elementWidth) / elementHeight;
                }
                // Create a new PDF instance
                const pdf = new jsPDF(orientation, 'mm', 'a4');
                // Add the image to the PDF
                pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                // Add clickable link
                pdf.link(0, 0, pdfWidth, pdfHeight, { url: 'https://pronetin.in/v/' + this.props.documentId });
                // Save the PDF
                pdf.save('certificate.pdf');
                // Restore original size
                node.style.width = originalWidth;
                node.style.height = originalHeight;
                this.setState({
                    progress_loader: false,
                    resetX: this.state.initialX,
                    resetY: this.state.initialY,
                })
            })
            .catch((error) => {
                console.error('Error occurred while trying to download the image', error);
                // Restore original size in case of error
                node.style.width = originalWidth;
                node.style.height = originalHeight;
                this.setState({
                    progress_loader: false,
                    resetX: this.state.initialX,
                    resetY: this.state.initialY,
                })
            });
    };

    downloadCertificate = async (event) => {
        event.preventDefault();
        this.setState({
            resetX: 0,
            resetY: 0,
            progress_loader: true,
            progress_opaque: true,
            cacheTs: Date.now(),
        })
        const node = document.getElementById('capture');
        const element = document.querySelector('#capture .elements');
        //const qr = document.getElementById('qr-div');
        // Calculate total width and height of the element
        const elementWidth = node.offsetWidth;
        const elementHeight = node.offsetHeight;
        // Scroll the element into view
        node.scrollIntoView();
        // Adjust the size of the captured element
        const originalWidth = node.style.width;
        const originalHeight = node.style.height;
        node.style.width = elementWidth * 2 + 'px';
        node.style.height = elementHeight * 2 + 'px';
        element.style.transform = 'scale(2) translate(25%, 25%)';
        //qr.style.transform = 'scale(2) translate(-25%, -25%)'
        const excludedElements = element.querySelectorAll('.exclude-scale');
        excludedElements.forEach((excludedElement) => {
            excludedElement.style.transform = 'scale(0.5) translate(-50%, -50%)';
        })
        // Capture the screenshot
        domtoimage
            .toPng(node, { quality: 1, style: { transform: 'scale(2) translate(25%, 25%)', } })
            .then(async (dataUrl) => {
                await download(dataUrl, 'certificate.png');
                // Restore original size
                node.style.width = originalWidth;
                node.style.height = originalHeight;
                element.style.transform = 'scale(1) translate(0%, 0%)';
                //qr.style.transform = 'scale(1) translate(0%, 0%)'
                const excludedElements = element.querySelectorAll('.exclude-scale');
                excludedElements.forEach((excludedElement) => {
                    excludedElement.style.transform = 'scale(1) translate(-0%, -0%)';
                })
                this.setState({
                    progress_loader: false,
                    resetX: this.state.initialX,
                    resetY: this.state.initialY,
                })
            })
            .catch((error) => {
                console.error('Error occurred while trying to download the image', error);
                // Restore original size in case of error
                node.style.width = originalWidth;
                node.style.height = originalHeight;
                element.style.transform = 'scale(1) translate(0%, 0%)';
                //qr.style.transform = 'scale(1) translate(0%, 0%)'
                const excludedElements = element.querySelectorAll('.exclude-scale');
                excludedElements.forEach((excludedElement) => {
                    excludedElement.style.transform = 'scale(1) translate(-0%, -0%)';
                })
                this.setState({
                    progress_loader: false,
                    resetX: this.state.initialX,
                    resetY: this.state.initialY,
                })
            });
    };

    downloadCertificatePdf = async (event) => {
        event.preventDefault();
        this.setState({
            resetX: 0,
            resetY: 0,
            progress_loader: true,
            progress_opaque: true,
            cacheTs: Date.now(),
        })
        const node = document.getElementById('capture');
        const element = document.querySelector('#capture .elements');
        //const qr = document.getElementById('qr-div');
        // Calculate total width and height of the element
        const elementWidth = node.offsetWidth;
        const elementHeight = node.offsetHeight;
        // Scroll the element into view
        node.scrollIntoView();
        // Adjust the size of the captured element
        const originalWidth = node.style.width;
        const originalHeight = node.style.height;
        node.style.width = elementWidth * 2 + 'px';
        node.style.height = elementHeight * 2 + 'px';
        element.style.transform = 'scale(2) translate(25%, 25%)';
        //qr.style.transform = 'scale(2) translate(25%, 25%)'
        const excludedElements = element.querySelectorAll('.exclude-scale');
        excludedElements.forEach((excludedElement) => {
            excludedElement.style.transform = 'scale(0.5) translate(-50%, -50%)';
        })
        // Capture the screenshot
        domtoimage
            .toPng(node, { quality: 1, style: { transform: 'scale(2) translate(25%, 25%)', } })
            .then(async (dataUrl) => {
                const imgData = dataUrl.split(',')[1]; // Remove data URL header
                // Calculate the aspect ratio
                const aspectRatio = elementWidth / elementHeight;
                // Determine orientation based on aspect ratio
                const orientation = aspectRatio > 1 ? 'landscape' : 'portrait';
                let pdfWidth, pdfHeight;
                if (orientation === 'landscape') {
                    pdfWidth = 297; // A4 paper width in mm
                    pdfHeight = (pdfWidth * elementHeight) / elementWidth;
                } else {
                    pdfHeight = 297; // A4 paper height in mm
                    pdfWidth = (pdfHeight * elementWidth) / elementHeight;
                }
                // Create a new PDF instance
                const pdf = new jsPDF(orientation, 'mm', 'a4');
                // Add the image to the PDF
                pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                // Add clickable link
                pdf.link(0, 0, pdfWidth, pdfHeight, { url: 'https://pronetin.in/v/' + this.props.documentId });
                // Save the PDF
                pdf.save('certificate.pdf');
                // Restore original size
                node.style.width = originalWidth;
                node.style.height = originalHeight;
                element.style.transform = 'scale(1) translate(0%, 0%)';
                //qr.style.transform = 'scale(1) translate(0%, 0%)'
                const excludedElements = element.querySelectorAll('.exclude-scale');
                excludedElements.forEach((excludedElement) => {
                    excludedElement.style.transform = 'scale(1) translate(-0%, -0%)';
                })
                this.setState({
                    progress_loader: false,
                    resetX: this.state.initialX,
                    resetY: this.state.initialY,
                })
            })
            .catch((error) => {
                console.error('Error occurred while trying to download the image', error);
                // Restore original size in case of error
                node.style.width = originalWidth;
                node.style.height = originalHeight;
                element.style.transform = 'scale(1) translate(0%, 0%)';
                //qr.style.transform = 'scale(1) translate(0%, 0%)'
                const excludedElements = element.querySelectorAll('.exclude-scale');
                excludedElements.forEach((excludedElement) => {
                    excludedElement.style.transform = 'scale(1) translate(-0%, -0%)';
                })
                this.setState({
                    progress_loader: false,
                    resetX: this.state.initialX,
                    resetY: this.state.initialY,
                })
            });
    };

    toggleViewType = (event) => {
        event.preventDefault();
        if (this.state.view_type === 'withdrawn') {
            this.setState({
                warning: {
                    message: "This certificate was withdrawn by the organisation.",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                }
            })
        } else {
            axios.defaults.withCredentials = true;
            axios.post(this.props.node_server_axios + 'toggle_certificate_view_type', {
                params: {
                    document_id: this.props.documentId,
                    email: this.props.email,
                    view_type: this.state.view_type === 'private' ? 'public' : 'private',
                },
            }).then(res => {
                if (res.data.message === 'success') {
                    this.setState({
                        warning: {
                            message: "Certificate is now " + (this.state.view_type === 'private' ? 'public' : 'private') + ".",
                            type: "success",
                            learnmore: false,
                            autoclose: true,
                        },
                        view_type: this.state.view_type === 'private' ? 'public' : 'private',
                    })
                } else {
                    this.setState({
                        warning: {
                            message: "Failed to update certificate view.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        }
                    })
                }
            });
        }
    }

    deleteDocument = async (e) => {
        e.preventDefault();
        //--------------------------Warning Action Trigger Start------------------------------
        let proceed_function = true;
        this.setState({
            warning_action: {
                message: "Delete Certificate",
                subtext: "Once the certificate is deleted it cannot be recovered. Do you want to Continue?",
                type: "warning",
                proceed_action: "true",
                proceed_function: proceed_function,
                back_action: false,
                close: false,
            }
        })
        if (proceed_function) {
            await new Promise((resolve) => {
                this.resumeFunction = resolve;
            });
            this.setState({
                warning_action: {
                    message: "",
                    subtext: "",
                    type: "warning",
                    proceed_action: false,
                    proceed_function: false,
                    back_action: false,
                    close: true,
                },
            })
        }
        //--------------------------Warning Action Trigger End------------------------------
        this.setState({
            progress_loader: true,
        })
        axios.post(this.props.node_server_axios + 'document_delete', {
            params: {
                documentId: this.props.documentId,
                email: this.props.email,
                batchId: this.state.batch_id,
            },
        }).then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'deleted') {
                    window.location = '../'
                } else {
                    this.setState({
                        warning: {
                            message: "Failed to delete document.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        progress_loader: false,
                    })
                }
            } else {
                this.setState({
                    warning: {
                        message: "An error has occoured.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    progress_loader: false,
                })
            }
        })
    }

    handleCertDragStop = (e, dragElement) => {
        this.setState({
            resetX: dragElement.x,
            resetY: dragElement.y,
        })
    };

    acceptCertificate = async (e) => {
        e.preventDefault();
        if (this.state.timeoutID) {
            clearTimeout(this.state.timeoutID);
        }
        if (this.state.csvData[0]['[Recepient Name]'] !== this.props.name) {
            //--------------------------Warning Action Trigger Start------------------------------
            let proceed_function = true;
            this.setState({
                warning_action: {
                    message: "Accept Certificate",
                    subtext: "Once accepted you cannot request for name change. Are you sure you want to continue with " + this.state.csvData[0]['[Recepient Name]'] + ".",
                    type: "warning",
                    proceed_action: "true",
                    proceed_function: proceed_function,
                    back_action: 'popupAcceptModal',
                    close: false,
                },
                popupAcceptModal: false,
            })
            if (proceed_function) {
                await new Promise((resolve) => {
                    this.resumeFunction = resolve;
                });
                this.setState({
                    warning_action: {
                        message: "",
                        subtext: "",
                        type: "warning",
                        proceed_action: false,
                        proceed_function: false,
                        back_action: false,
                        close: true,
                    },
                })
            }
            //--------------------------Warning Action Trigger End------------------------------
        }
        this.setState({
            progress_loader: true,
            progress_opaque: false,
            popupAcceptModal: false,
        })
        axios.post(this.props.node_server_axios + 'document_accept', {
            params: {
                documentId: this.props.documentId,
                email: this.props.email,
                batchId: this.state.batch_id,
            },
        }).then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'accepted') {
                    this.setState({
                        warning: {
                            message: "Certificate Accepted.",
                            type: "success",
                            learnmore: false,
                            autoclose: true,
                        },
                        accepted: 'true',
                        progress_loader: false,
                    })
                } else {
                    this.setState({
                        warning: {
                            message: "An error has occoured.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        progress_loader: false,
                    })
                }
            } else {
                this.setState({
                    warning: {
                        message: "An error has occoured.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    progress_loader: false,
                })
            }
        })
    }

    rejectCertificate = async (e) => {
        if (this.state.timeoutID) {
            clearTimeout(this.state.timeoutID);
        }
        e.preventDefault();
        //--------------------------Warning Action Trigger Start------------------------------
        let proceed_function = true;
        this.setState({
            warning_action: {
                message: "Reject Document",
                subtext: "If you reject the certificate, you will not be able to access it again. Do you wish to continue?",
                type: "warning",
                proceed_action: "true",
                proceed_function: proceed_function,
                back_action: false,
                close: false,
            },
            popupAcceptModal: false,
        })
        if (proceed_function) {
            await new Promise((resolve) => {
                this.resumeFunction = resolve;
            });
            this.setState({
                warning_action: {
                    message: "",
                    subtext: "",
                    type: "warning",
                    proceed_action: false,
                    proceed_function: false,
                    back_action: false,
                    close: true,
                },
            })
        }
        //--------------------------Warning Action Trigger End------------------------------
        this.setState({
            progress_loader: true,
            progress_opaque: false,
        })
        axios.post(this.props.node_server_axios + 'document_reject', {
            params: {
                documentId: this.props.documentId,
                email: this.props.email,
                batchId: this.state.batch_id,
            },
        }).then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'deleted') {
                    window.location = '../'
                } else {
                    this.setState({
                        warning: {
                            message: "An error has occoured.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        progress_loader: false,
                    })
                }
            } else {
                this.setState({
                    warning: {
                        message: "An error has occoured.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    progress_loader: false,
                })
            }
        })
    }

    requestUpdate = async (e) => {
        e.preventDefault();
        if (this.state.timeoutID) {
            clearTimeout(this.state.timeoutID);
        }
        //--------------------------Warning Action Trigger Start------------------------------
        let proceed_function = true;
        this.setState({
            warning_action: {
                message: "Request Edit Name",
                subtext: this.state.csvData[0]['[Recepient Name]'] + " ➤ " + this.props.name + ". Once requested this action cannot be reverted. Do you wish to Continue?",
                type: "success",
                proceed_action: "true",
                proceed_function: proceed_function,
                back_action: false,
                close: false,
            },
            popupAcceptModal: false,
        })
        if (proceed_function) {
            await new Promise((resolve) => {
                this.resumeFunction = resolve;
            });
            this.setState({
                warning_action: {
                    message: "",
                    subtext: "",
                    type: "warning",
                    proceed_action: false,
                    proceed_function: false,
                    back_action: false,
                    close: true,
                },
            })
        }
        //--------------------------Warning Action Trigger End------------------------------
        axios.post(this.props.node_server_axios + 'document_update_request', {
            params: {
                documentId: this.props.documentId,
                email: this.props.email,
                batchId: this.state.batch_id,
                org_id: this.state.org_id,
                new_data: this.props.name,
                old_data: this.state.csvData[0]['[Recepient Name]'],
                type: "Incorrect Name",
            },
        }).then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'accepted') {
                    this.setState({
                        warning: {
                            message: "Waiting for approval from organisation",
                            type: "success",
                            learnmore: false,
                            autoclose: false,
                        },
                        accepted: 'true',
                        pendingUpdateRequest: true,
                    })
                } else {
                    this.setState({
                        warning: {
                            message: "An error has occoured.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        }
                    })
                }
            } else {
                this.setState({
                    warning: {
                        message: "An error has occoured.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    }
                })
            }
        })
    }

    toggleMore = () => {
        var subNav = document.getElementsByClassName('sub-nav')[0];
        // var wrapperElem = document.getElementsByClassName('wrapper')[0];
        if (subNav.style.display !== 'flex') {
            subNav.style.display = 'flex';
            // wrapperElem.style.display = 'none';
            this.setState({
                moreBtn: 'back',
                hideFixedControls: true,
            })
        } else {
            subNav.style.display = 'none';
            // wrapperElem.style.display = 'flex';
            this.setState({
                moreBtn: 'more',
                hideFixedControls: false,
            })
        }
    }

    copyUrl = (e) => {
        e.preventDefault();
        const url = this.state.url;
        const textarea = document.createElement("textarea");
        textarea.value = url;
        textarea.setAttribute("readonly", "");
        textarea.style.position = "absolute";
        textarea.style.left = "-9999px";
        document.body.appendChild(textarea);

        textarea.select();
        document.execCommand("copy");

        document.body.removeChild(textarea);

        this.setState({
            warning: {
                message: "URL copied to clipboard.",
                type: "success",
                learnmore: false,
                autoclose: true,
            }
        })
    }

    handleStarClick = (rating) => {
        this.setState({ rating });
    };

    handleTextChange = (event) => {
        const newText = event.target.value;
        if (newText.length <= 100) {
            this.setState({ feedback_text: newText, feedback_warning: false });
        } else {
            this.setState({ feedback_warning: "Character limit exceeded (100 characters)" });
        }
    };

    handleStarHover = (index) => {
        const elements = document.querySelectorAll('.star-rating .star');
        for (let i = 0; i < index; i++) {
            elements[i].classList.add('active');
        }
    };

    handleStarLeave = () => {
        const elements = document.querySelectorAll('.star-rating .star');
        elements.forEach((element) => {
            element.classList.remove('active');
        });
    };

    skipFeedback = () => {
        this.setState({ feedback: false });

        const timeoutID = setTimeout(() => {
            this.setState({
                popupAcceptModal: true,
            });
        }, 5000);
        this.setState({ timeoutID });
    }

    submitFeedback = () => {
        if (this.state.rating > 0) {
            axios.post(this.props.node_server_axios + 'submit_feedback', {
                params: {
                    batch_id: this.state.batch_id,
                    email: this.state.feedback_anonymous === 1 ? this.props.userId : this.props.email,
                    name: this.state.feedback_anonymous === 1 ? null : this.props.name,
                    feedback: this.state.feedback_text,
                    rating: this.state.rating,
                },
            }).then(async (res) => {
                if (res.data !== undefined) {
                    if (res.data.message === 'success') {
                        this.setState({
                            warning: {
                                message: "Feedback Submitted.",
                                type: "success",
                                learnmore: false,
                                autoclose: true,
                            },
                            feedback: false,
                            feedback_text: '',
                            feedback_warning: false,
                        })

                        const timeoutID = setTimeout(() => {
                            this.setState({
                                popupAcceptModal: true,
                            });
                        }, 5000);
                        this.setState({ timeoutID });

                    } else {
                        this.setState({ feedback_warning: "An error has occoured" });
                    }
                } else {
                    this.setState({ feedback_warning: "An error has occoured" });
                }
            })
        } else {
            this.setState({ feedback_warning: "Please give a star rating (1-5 Stars)" });
        }
    }

    recenterCertificate = () => {
        this.setState({
            resetX: this.state.initialX,
            resetY: this.state.initialY,
            sliderValue: 3,
            sliderClass: "slider-3",
        });
    }

    toggleShareModal = (e) => {
        e.preventDefault();
        this.setState({
            shareModal: !this.state.shareModal,
        });
    }

    handleShare(event) {
        event.preventDefault();
        const { url, title, description } = this.state;
        const socialMedia = event.currentTarget.getAttribute('data-network');
        const shareUrl = getShareUrl(socialMedia, url, title, description);
        window.open(shareUrl, '_blank', 'width=550,height=450');
    }

    toggleNameChangeModal = (e) => {
        e.preventDefault();
        this.setState({
            nameChangeModal: !this.state.nameChangeModal,
        });
    }

    toggleMoreInfo = () => {
        this.setState({
            moreInfo: !this.state.moreInfo,
        });
    }

    toggleDownloadModal = (e) => {
        e.preventDefault();
        this.setState({
            downloadModal: !this.state.downloadModal,
        });
    }

    handleFormatChange = (event) => {
        this.setState({ format: event.target.value });
    };

    handleQualityChange = (event) => {
        this.setState({ quality: event.target.value });
    };

    processDownload = (e) => {
        this.setState({
            downloadModal: false,
        });
        const { format, quality } = this.state;
        switch (format + '_' + quality) {
            case 'image_medium':
                this.downloadCertificateMed(e);
                break;
            case 'image_high':
                this.downloadCertificate(e);
                break;
            case 'pdf_medium':
                this.downloadCertificatePdfMed(e);
                break;
            case 'pdf_high':
                this.downloadCertificatePdf(e);
                break;
            default:
                this.downloadCertificate(e);
        }
    };

    downloadCertificateAfterAccept = (e) => {
        e.preventDefault();
        this.setState({
            warning: {
                message: "Accept Certificate, to Download.",
                type: "warning",
                learnmore: false,
                autoclose: true,
            },
        })
    }

    togglePopupAcceptModal = async (e) => {
        e.preventDefault();
        if (this.state.timeoutID) {
            clearTimeout(this.state.timeoutID);
        }
        const timeoutID = setTimeout(() => {
            this.setState({
                popupAcceptModal: true,
            });
        }, 5000);
        this.setState({
            popupAcceptModal: !this.state.popupAcceptModal,
            timeoutID: timeoutID,
        })
    }

    render() {
        const { rating, feedback_text } = this.state;
        const stars = Array.from({ length: 5 }, (_, index) => (
            <span
                key={index}
                className={`star ${index < rating ? 'filled' : 'empty'}`}
                onClick={() => this.handleStarClick(index + 1)}
                onMouseEnter={() => this.handleStarHover(index)}
                onMouseLeave={this.handleStarLeave}
            >
                ★
            </span>
        ));

        return (
            <React.Fragment>
                {this.state.shareModal && (
                    <div className='social-share-modal'>
                        <div className='wrapper'>
                            <button className='close-btn' onClick={this.toggleShareModal}><i className="fa-regular fa-circle-xmark"></i> Close</button>
                        </div>
                        <div className='container'>
                            {this.state.view_type === 'private' && (
                                <div className='private-share-warning'>
                                    <i className="fa-solid fa-lock"></i>
                                    <p>Your certificate is now <b>Private</b>. If shared, viewing will be restricted. Change it to Public, before sharing.</p>
                                    <button onClick={this.toggleViewType}>Make Public <i className="fa-solid fa-unlock"></i></button>
                                </div>
                            )}
                            <h1>Share your certificate</h1>
                            <button onClick={this.handleShare} data-network="linkedin"><i className="fa-brands fa-linkedin"></i> Share on linkedin</button>
                            <button onClick={this.handleShare} data-network="facebook"><i className="fa-brands fa-facebook-f"></i> Share on facebook</button>
                            <button onClick={this.handleShare} data-network="twitter"><i className="fa-brands fa-twitter"></i> Share on twitter</button>
                            <button onClick={this.copyUrl}><i className="fa-regular fa-copy"></i> Copy URL</button>
                        </div>
                    </div>
                )}
                {this.state.csvData && this.state.csvData[0]['[Recepient Name]'] !== this.props.name && this.state.nameChangeModal && this.state.feedback === false && !this.state.accepted && (
                    <div className='element-modal transfer-modal'>
                        <div className='content'>
                            <button className='close-btn' onClick={this.toggleNameChangeModal}><i className="fa-solid fa-x"></i></button>
                            <div className='container'>
                                <h1>Request Name Edit</h1>
                            </div>
                            <p style={{ color: '#818181' }}>We have noticed that the name on this certificate does not match your account name, would you like to request {this.state.org_name} to update it.</p>
                            <p style={{ color: '#818181' }}>Your name in certificate will be changed from <b style={{ color: '#D5415A', fontWeight: '600' }}>{this.state.csvData[0]['[Recepient Name]']}</b> to <b style={{ color: '#2BCEAD', fontWeight: '600' }}>{this.props.name}</b>.</p>
                            <button type="button" onClick={this.togglePopupAcceptModal}>Request Edit <i className="fa-solid fa-arrow-right-long"></i></button>
                            <p style={{ color: '#818181' }}>Name in account is wrong? <a href='/profile' style={{ color: '#2977c9' }}>Update Account Name</a></p>
                            <p><i className="fa-solid fa-circle-info"></i> Once this document is accepted changes cannot be requested</p>
                        </div>
                    </div>
                )}
                {this.state.popupAcceptModal && (
                    // <div className='element-modal transfer-modal transparent'>
                    //     <div className='content'>
                    //         <button className='close-btn' onClick={this.togglePopupAcceptModal}><i className="fa-solid fa-x"></i></button>
                    //         <div className='container'>
                    //             <h1>{this.state.csvData[0]['[Recepient Name]'] !== this.props.name && ("Update / ")}Accept Certificate</h1>
                    //         </div>
                    //         {this.state.csvData[0]['[Recepient Name]'] !== this.props.name ? <p style={{ color: '#818181' }}>Would you like to edit your name on this certificate from <br></br><b style={{ color: '#000' }}>{this.state.csvData[0]['[Recepient Name]']} <i style={{ color: '#D5415A' }} className="fa-solid fa-arrow-right-long"></i> {this.props.name}</b><br></br> Else, if you wish to keep the certificate as it is, you may click on 'Accept'.</p> : <p style={{ color: '#818181' }}>Would you like to accept this certificate, Accepting will unlock further actions</p>}
                    //         <div className='cert-action-btns'>
                    //             {this.state.csvData[0]['[Recepient Name]'] !== this.props.name && (<button className='name-change' onClick={this.requestUpdate}>Request Name Edit</button>)}
                    //             <button onClick={this.acceptCertificate}>Accept</button>
                    //             <button className='skip-btn' onClick={this.togglePopupAcceptModal}>Preview</button>
                    //         </div>
                    //     </div>
                    // </div>
                    <div className='element-modal transfer-modal transparent'>
                        {this.state.csvData[0]['[Recepient Name]'] !== this.props.name ?
                            <div className='content'>
                                <button className='close-btn' onClick={this.togglePopupAcceptModal}><i className="fa-solid fa-x"></i></button>
                                <div className='container'>
                                    <h1>Your name might be wrong in certificate</h1>
                                </div>
                                <p style={{ color: '#818181' }}>The name on your profile and the name on your certificate does not match, Please choose the right one</p>
                                <div className='cert-action-btns'>
                                    <div className='img-choose'>
                                        <div className='container' onClick={this.acceptCertificate}>
                                            <div className='name'>{this.state.csvData[0]['[Recepient Name]']}</div>
                                            <button>Choose</button>
                                        </div>
                                        <div className='container' onClick={this.requestUpdate}>
                                            <div className='name'>{this.props.name}</div>
                                            <button>Choose</button>
                                        </div>
                                    </div>
                                    <button className='skip-btn' onClick={this.togglePopupAcceptModal}>Skip</button>
                                    <a href='/account' className='anchor-btn'>Both not your name?</a>
                                </div>
                            </div>
                            :
                            <div className='content'>
                                <button className='close-btn' onClick={this.togglePopupAcceptModal}><i className="fa-solid fa-x"></i></button>
                                <div className='container'>
                                    <h1>Accept Certificate</h1>
                                </div>
                                <p style={{ color: '#818181' }}>Would you like to accept this certificate, Accepting will unlock further actions</p>
                                <div className='cert-action-btns'>
                                    <button onClick={this.acceptCertificate}>Accept</button>
                                    <button className='skip-btn' onClick={this.togglePopupAcceptModal}>Preview</button>
                                </div>
                            </div>
                        }
                    </div>
                )}
                <div className={this.props.minNav ? "element max-element" : "element min-element"}>
                    {this.state.feedback !== false && this.state.accepted !== 'true' && (
                        <div className='feedback-modal'>
                            <div className='container'>
                                <h2>Feedback</h2>
                                <p>Please give a feedback for the event <span>{this.state.event_name}</span></p>
                                <div className="star-rating">{stars}</div>
                                <textarea placeholder='feedback (optional)' value={feedback_text} onChange={this.handleTextChange} maxLength="500"></textarea>
                                {this.state.feedback_warning && (<p className='warning'>{this.state.feedback_warning}</p>)}
                                {this.state.feedback_anonymous === 1 && (<p className='message-anonymous'><i className="fa-solid fa-mask"></i> This feedback will be kept anonymous</p>)}
                                <div className='button-sec'>
                                    {this.state.feedback === 0 && (<button className='skip' onClick={this.skipFeedback}>Skip</button>)}
                                    <button onClick={this.submitFeedback}>Submit Feedback <i className="fa-solid fa-arrow-right"></i></button>
                                </div>
                            </div>
                        </div>
                    )}
                    {this.state.downloadModal && (
                        <div className='element-modal transfer-modal'>
                            <div className='content max-width-400-modal'>
                                <button className='close-btn' onClick={this.toggleDownloadModal}>
                                    <i className="fa-solid fa-x"></i>
                                </button>
                                <div className='container'>
                                    <h1>Download Certificate</h1>
                                </div>
                                <div className='radio-wrapper'>
                                    <h2>Select download format</h2>
                                    <div className='radio'>
                                        <input
                                            type='radio'
                                            name='format'
                                            value='image'
                                            checked={this.state.format === 'image'}
                                            onChange={this.handleFormatChange}
                                        />
                                        <p>Image</p>
                                    </div>
                                    <div className='radio'>
                                        <input
                                            type='radio'
                                            name='format'
                                            value='pdf'
                                            checked={this.state.format === 'pdf'}
                                            onChange={this.handleFormatChange}
                                        />
                                        <p>PDF</p>
                                    </div>
                                </div>
                                <div className='radio-wrapper'>
                                    <h2>Select download quality</h2>
                                    <div className='radio'>
                                        <input
                                            type='radio'
                                            name='quality'
                                            value='medium'
                                            checked={this.state.quality === 'medium'}
                                            onChange={this.handleQualityChange}
                                        />
                                        <p>Medium</p>
                                    </div>
                                    <div className='radio'>
                                        <input
                                            type='radio'
                                            name='quality'
                                            value='high'
                                            checked={this.state.quality === 'high'}
                                            onChange={this.handleQualityChange}
                                        />
                                        <p>High</p>
                                    </div>
                                </div>
                                <button type="button" onClick={this.processDownload}>
                                    Download <i className="fa-solid fa-arrow-right-long"></i>
                                </button>
                            </div>
                        </div>
                    )}
                    <div className="container">
                        <div className='header'>
                            <button className='back-btn' onClick={() => window.location.href = "/"}><i className="fa-solid fa-arrow-left-long"></i> Back</button>
                            <div className='container-left'>
                                <h1>Certificate<label>viewer</label></h1>
                                <button className='more-action-btn' onClick={this.toggleMore}>{this.state.moreBtn} <i className="fa-solid fa-ellipsis-vertical"></i></button>
                            </div>
                            {/* <button className='btn-issue' onClick={this.checkEligibility}><i className="fa-solid fa-stamp"></i> Create Certificate</button> */}
                        </div>
                        <div className='tiles marketplace pg-certficate_viewer'>
                            {this.state.certView && (
                                <div className='sub-nav'>
                                    {this.state.moreInfo ?
                                        <div className='document-info'>
                                            <h3>Certificate Information</h3>
                                            <p>Event : {this.state.event_name}</p>
                                            <p>Title : {this.state.cert_title}</p>
                                            <p>Issue Date : {this.state.issue_date}</p>
                                            <p>Issuer : {this.state.org_name}</p>
                                            <p>Website : <a href={"https://" + this.state.domain} target="_blank" rel="noreferrer">
                                                {this.state.domain}
                                            </a></p>
                                            {this.state.csvData[0]['[Email]'] !== this.props.email && (<p className='note'>Owner : {this.state.csvData[0]['[Email]']}</p>)}
                                            <button className='more-info' onClick={this.toggleMoreInfo}>Less <i className="fa-solid fa-angle-up"></i></button>
                                        </div>
                                        :
                                        <div className='document-info'>
                                            <h3>Certificate Information</h3>
                                            <p>Event : {this.state.event_name}</p>
                                            {this.state.csvData[0]['[Email]'] !== this.props.email && (<p className='note'>Owner : {this.state.csvData[0]['[Email]']}</p>)}
                                            <button className='more-info' onClick={this.toggleMoreInfo}>More <i className="fa-solid fa-angle-down"></i></button>
                                        </div>
                                    }
                                    <h1><i className="fa-solid fa-gamepad"></i> Controls</h1>
                                    {this.state.accepted === 'true' ?
                                        <div className='nav-container'>
                                            <a className='nav' href="foo" onClick={this.toggleDownloadModal}>
                                                <i className="fa-solid fa-download"></i>
                                                Download
                                            </a>
                                            {/* <a className='nav' href="foo" onClick={this.downloadCertificatePdf}>
                                                <i className="fa-regular fa-file-pdf"></i>
                                                Download PDF
                                            </a> */}
                                            {this.state.cert_attachment && this.state.cert_attachment !== 'null' && (
                                                <a className='nav' href="foo" onClick={this.viewAttachment}>
                                                    <i className="fa-solid fa-paperclip"></i>
                                                    View Attachment
                                                </a>
                                            )}
                                            <a className='nav' href="foo" onClick={this.toggleShareModal}>
                                                <i className="fa-solid fa-share"></i>
                                                Share
                                            </a>
                                            {/* <a className='nav' href={`https://www.linkedin.com/shareArticle?url=${encodeURIComponent(this.state.url)}&title=${encodeURIComponent(this.state.title)}&summary=${encodeURIComponent(this.state.description)}`} target="_blank" rel="noreferrer">
                                                <i className="fa-brands fa-linkedin-in"></i>
                                                Linkedin
                                            </a> */}
                                            <a className='nav warn' href="foo" onClick={this.deleteDocument}>
                                                <i className="fa-solid fa-trash-can"></i>
                                                Delete
                                            </a>
                                            <div className='view-toggle' onClick={this.toggleViewType}>
                                                {this.state.view_type === 'public' ?
                                                    <div className='toggle public'>
                                                        <div className='slider'>
                                                            <i className="fa-solid fa-unlock"></i>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className='toggle private'>
                                                        <div className='slider'>
                                                            <i className="fa-solid fa-lock"></i>
                                                        </div>
                                                    </div>
                                                }
                                                {this.state.view_type === 'public' ?
                                                    <p>This certificate can be viewed by anyone with the link</p>
                                                    :
                                                    <p>This certificate is removed from public view</p>
                                                }
                                            </div>
                                            {this.state.pendingUpdateRequest && (
                                                <p className='pending-name-change'><b><i className="fa-solid fa-circle-info"></i> Name change request status</b><br></br>Pending confirmation from {this.state.org_name}, for further queries contact <a href={'mailto:' + this.state.contact}>{this.state.contact}</a></p>
                                            )}
                                        </div>
                                        :
                                        <div className='nav-container'>
                                            {this.state.csvData[0]['[Recepient Name]'] !== this.props.name && (
                                                <div className='request'>
                                                    <p>We have noticed that the name on this certificate does not match your account name, would you like to request {this.state.org_name} to update it.</p>
                                                    <a className='btn' href="foo" onClick={this.togglePopupAcceptModal}>
                                                        Request Edit <i className="fa-solid fa-arrow-right-long"></i>
                                                    </a>
                                                    <p><i className="fa-solid fa-circle-info"></i> Once this document is accepted changes cannot be requested</p>
                                                </div>
                                            )}
                                            <hr></hr>
                                            {!this.state.feedback && (
                                                <a className='nav green' href="foo" onClick={this.acceptCertificate}>
                                                    {/* continue from here */}
                                                    <i className="fa-regular fa-circle-check"></i>
                                                    Accept
                                                </a>
                                            )}
                                            <a className='nav warn' href="foo" onClick={this.rejectCertificate}>
                                                <i className="fa-regular fa-circle-xmark"></i>
                                                Reject
                                            </a>
                                            <a className='nav' href="foo" onClick={this.downloadCertificateAfterAccept}>
                                                <i className="fa-solid fa-download"></i>
                                                Download
                                            </a>
                                        </div>
                                    }
                                </div>
                            )}
                            <div className='wrapper'>
                                <button className='recenter-certificate' onClick={this.recenterCertificate}><i className="fa-solid fa-arrows-to-circle"></i></button>
                                <div className='cropper-rel'>
                                    {this.state.certView && (
                                        <div className='image-container'
                                            onTouchStart={this.handlePinchStart}
                                            onTouchMove={this.handlePinchMove}
                                            onTouchEnd={this.handlePinchEnd}
                                            ref={this.imageDivRef}
                                        >
                                            <div className={this.state.sliderClass + " origin"} style={{ '--scale-width': this.state.scaleFactor }}>
                                                <Draggable position={{ x: this.state.resetX, y: this.state.resetY }} onStop={this.handleCertDragStop}>
                                                    <div className='cert-container' id="capture" style={{ boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px', width: this.state.certWidth, height: this.state.certHeight }}>
                                                        <div className='elements of-hidden' ref={this.certDivRef}>
                                                            <img src={this.state.withdrawn !== 'true' && this.state.view_type !== 'withdrawn' ? "https://pronetin-new.s3.amazonaws.com/batch/" + encodeURIComponent(this.state.org_id) + "/" + encodeURIComponent(this.state.cert_bg) + "?cacheblock=" + this.state.cacheTs : "https://pronetin-new.s3.amazonaws.com/templates/certificates/background/" + this.state.withdrawn_type + "?cacheblock=" + this.state.cacheTs} crossOrigin="anonymous" alt='certificate template' className='exclude-scale' onLoad={this.completedLoad}></img>
                                                            {this.state.elements && this.state.elements.length ?
                                                                this.state.elements.map((element, index) => {
                                                                    if (element.type === "image") {
                                                                        return <ViewImage element_id={index} key={element.timeStamp + "-" + index} data={element} />
                                                                    } else if (element.type === "table") {
                                                                        return <ViewTable element_id={index} key={element.timeStamp + "-" + index} data={element} variables={this.state.variables} csvData={this.state.csvData} />
                                                                    } else if (element.type === "signature") {
                                                                        return <ViewSignee element_id={index} key={element.timeStamp + "-" + index} data={element} node_server_axios={this.props.node_server_axios} batch_id={this.state.batch_id} />
                                                                    } else if (element.type !== "QR") {
                                                                        return <ViewElement element_id={index} key={element.timeStamp + "-" + index} data={element} variables={this.state.variables} csvData={this.state.csvData} />
                                                                    } else {
                                                                        return null
                                                                    }
                                                                })
                                                                : ""}
                                                            <ViewQR data={this.props.documentId} size={this.state.QRSize} position={this.state.elements.find(element => element.type === "QR")} />
                                                        </div>
                                                    </div>
                                                </Draggable>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {this.state.certView && !this.state.hideFixedControls && (
                                    <div className="mob-actions mobile-only fixed-controls">
                                        {this.state.accepted === 'true' ?
                                            <div className='nav-container'>
                                                <a className='nav' href="foo" onClick={this.toggleDownloadModal}>
                                                    <i className="fa-solid fa-download"></i>
                                                    Download
                                                </a>
                                                {this.state.cert_attachment && this.state.cert_attachment !== 'null' && (
                                                    <a className='nav' href="foo" onClick={this.viewAttachment}>
                                                        <i className="fa-solid fa-paperclip"></i>
                                                        Attachment
                                                    </a>
                                                )}
                                                <a className='nav' href="foo" onClick={this.toggleShareModal}>
                                                    <i className="fa-solid fa-share"></i>
                                                    Share
                                                </a>
                                                <a className='nav' href={`https://www.linkedin.com/shareArticle?url=${encodeURIComponent(this.state.url)}&title=${encodeURIComponent(this.state.title)}&summary=${encodeURIComponent(this.state.description)}`} target="_blank" rel="noreferrer">
                                                    <i className="fa-brands fa-linkedin-in"></i>
                                                    Linkedin
                                                </a>
                                                <a className='nav warn' href="foo" onClick={this.deleteDocument}>
                                                    <i className="fa-solid fa-trash-can"></i>
                                                    Delete
                                                </a>
                                                <a className={this.state.view_type === 'public' ? 'nav green' : 'nav warn'} href="foo" onClick={this.toggleViewType}>
                                                    {this.state.view_type === 'public' ?
                                                        <i className="fa-solid fa-unlock"></i>
                                                        :
                                                        <i className="fa-solid fa-lock"></i>
                                                    }
                                                    {this.state.view_type === 'public' ?
                                                        'Public'
                                                        :
                                                        'Private'
                                                    }
                                                </a>
                                            </div>
                                            :
                                            <div className='nav-container'>
                                                {!this.state.feedback && (
                                                    <a className='nav green' href="foo" onClick={this.acceptCertificate}>
                                                        {/* continue from here */}
                                                        <i className="fa-regular fa-circle-check"></i>
                                                        Accept
                                                    </a>
                                                )}
                                                <a className='nav warn' href="foo" onClick={this.rejectCertificate}>
                                                    <i className="fa-regular fa-circle-xmark"></i>
                                                    Reject
                                                </a>
                                                <a className='nav' href="foo" onClick={this.downloadCertificateAfterAccept}>
                                                    <i className="fa-solid fa-download"></i>
                                                    Download
                                                </a>
                                                {this.state.csvData[0]['[Recepient Name]'] !== this.props.name && (
                                                    <a className='nav yellow' href="foo" onClick={this.toggleNameChangeModal}>
                                                        <i className="fa-solid fa-triangle-exclamation"></i>
                                                        Name&nbsp;Change
                                                    </a>
                                                )}
                                            </div>
                                        }
                                    </div>
                                )}
                                <div className='cropper-zoom cert-viewer'>
                                    <div className='container onboarding-zoom'>
                                        <p>Zoom</p>
                                        <input type="range" min="1" max="7" className="slider" value={this.state.sliderValue} onChange={this.updateZoomInput}></input>
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                            }
                            {
                                this.state.warning_action.message !== "" ? <WarningAction data={this.state.warning_action} fetchWarningActionState={this.fetchWarningActionState} resumeFunction={this.resumeFunction} /> : ""
                            }
                        </div>
                    </div>
                </div>
                {
                    this.state.progress_loader && (<ProgressLoader opaque={this.state.progress_opaque} message={this.state.percentage_progress > 0 ? this.state.percentage_progress + "% Loaded" : false} />)
                }
            </React.Fragment>
        );
    }
}

function getShareUrl(network, url, title, description) {
    switch (network) {
        case 'facebook':
            return `https://www.facebook.com/sharer.php?u=${encodeURIComponent(url)}`;
        case 'twitter':
            return `https://twitter.com/share?url=${encodeURIComponent(url)}&text=${encodeURIComponent(title)}`;
        case 'linkedin':
            return `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}&summary=${encodeURIComponent(description)}`;
        default:
            return null;
    }
}

export default CertificateViewerComponent;