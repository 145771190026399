import React from 'react';
import './css/element.css';
import axios from 'axios';

import Warning from '../components/warning';
import WarningAction from '../components/warning_action';

class MyDesignComponent extends React.Component {
    constructor(props) {
        super(props);
        this.fetchWarningState.bind(this);
        this.state = {
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            warning_action: {
                message: "",
                subtext: "",
                type: "warning",
                proceed_action: false,
                proceed_function: false,
                back_action: false,
                close: true,
            },
            certTemplate: null,
            savedTemplates: [],
            shareModal: false,
            modal_shared_to: [],
            modal_save_id: '',
            modalEmail: '',
            issuedTemplates: [],
            running_plan: 'free',
            save_slots: 3,
            moreBtn: 'more',
            shareEmailProcessing: false,
        };
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    fetchWarningActionState = (data) => {
        this.setState({
            warning_action: {
                message: data,
            }
        })
    }

    toggleMore = () => {
        var subNav = document.getElementsByClassName('sub-nav')[0];
        if (subNav.classList.contains('show')) {
            subNav.classList.remove('show');
            subNav.classList.add('hide');
            this.setState({
                moreBtn: 'more',
            });
        } else {
            subNav.classList.add('show');
            subNav.classList.remove('hide');
            this.setState({
                moreBtn: 'back',
            });
        }
    }

    componentDidMount() {
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'get_draft', {
            params: {
                email: this.props.email,
            },
        }).then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'draft') {
                    this.setState({
                        certTemplate: res.data.draft_bg !== null ? res.data.draft_bg : null,
                    })
                }
            }
        })
        axios.post(this.props.node_server_axios + 'get_saved_templates', {
            params: {
                email: this.props.email,
            },
        }).then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'saved templates') {
                    this.setState({
                        savedTemplates: res.data.saved_templates,
                    })
                }
            }
        })
        axios.post(this.props.node_server_axios + 'get_organisation_data', {
            params: {
                org_id: this.props.org_id,
            }
        }).then((res) => {
            if (res.data !== undefined && res.data.message === 'success') {
                let save_slots = 3;
                if (res.data.running_plan !== null && res.data.running_plan !== 'free') {
                    save_slots = 6;
                } else {
                    save_slots = 3;
                }
                this.setState({
                    running_plan: res.data.running_plan,
                    save_slots: save_slots,
                });
            } else {
                window.location = "/signin";
            }
        })
        // axios.post(this.props.node_server_axios + 'get_issued_templates', {
        //     params: {
        //         email: this.props.email,
        //     },
        // }).then(async (res) => {
        //     if (res.data !== undefined) {
        //         if (res.data.message === 'issued templates') {
        //             this.setState({
        //                 issuedTemplates: res.data.issued_templates,
        //             })
        //         }
        //     }
        // })
    }

    // checkEligibility = () => {
    //     if (this.props.user_type !== "organisation") {
    //         this.setState({
    //             warning: {
    //                 message: "Sorry, You need an organisation account to issue certificates.",
    //                 type: "warning",
    //                 learnmore: "create",
    //                 autoclose: false,
    //             }
    //         })
    //     } else {
    //         window.location = "/issue";
    //     }
    // }

    saveAsTemplate = () => {
        if (this.state.certTemplate === null) {
            this.setState({
                warning: {
                    message: "No draft available to save.",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                }
            })
        } else if (this.state.savedTemplates === null || this.state.savedTemplates.length < this.state.save_slots) {
            axios.post(this.props.node_server_axios + 'save_as_template', {
                params: {
                    email: this.props.email,
                },
            }).then(async (res) => {
                if (res.data !== undefined) {
                    if (res.data.message === 'saved') {
                        this.setState({
                            warning: {
                                message: "Draft Saved.",
                                type: "success",
                                learnmore: false,
                                autoclose: true,
                            },
                            savedTemplates: res.data.saved_templates,
                        })
                    } else if (res.data.message === 'limit reached') {
                        this.setState({
                            warning: {
                                message: "Limit to save new designs reached.",
                                type: "warning",
                                learnmore: false,
                                autoclose: true,
                            },
                        })
                    } else {
                        this.setState({
                            warning: {
                                message: "Failed to save file.",
                                type: "warning",
                                learnmore: false,
                                autoclose: true,
                            }
                        })
                    }
                }
            })
        } else {
            this.setState({
                warning: {
                    message: "Limit Reached. Only " + this.state.save_slots + " drafts can be saved, please delete unused design to save new.",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                }
            })
        }

        if (window.innerWidth < 820) {
            var subNav = document.getElementsByClassName('sub-nav')[0];
            subNav.classList.remove('show');
            subNav.classList.add('hide');
            this.setState({
                moreBtn: 'more',
            });
        }
    }

    deleteSavedTemplate = async (save_id) => {
        //--------------------------Warning Action Trigger Start------------------------------
        let proceed_function = true;
        this.setState({
            warning_action: {
                message: "Permanently Delete",
                subtext: "Once deleted saved template cannot be recovered, Shared users added to this will also be removed.",
                type: "warning",
                proceed_action: "true",
                proceed_function: proceed_function,
                back_action: false,
                close: true,
            }
        })
        if (proceed_function) {
            await new Promise((resolve) => {
                this.resumeFunction = resolve;
            });
            this.setState({
                warning_action: {
                    message: "",
                    subtext: "",
                    type: "warning",
                    proceed_action: false,
                    proceed_function: false,
                    back_action: false,
                    close: true,
                },
            })
        }
        //--------------------------Warning Action Trigger End------------------------------
        axios.post(this.props.node_server_axios + 'delete_saved_templates', {
            params: {
                email: this.props.email,
                save_id: save_id,
            },
        }).then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'deleted') {
                    this.setState({
                        savedTemplates: res.data.saved_templates,
                        warning: {
                            message: "Saved design removed.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        }
                    })
                }
            }
        })
    }

    toggleSharePanel = (shared_to, save_id) => {
        this.setState({
            modal_shared_to: shared_to,
            modal_save_id: save_id,
            shareModal: !this.state.shareModal,
            modalEmail: '',
        })
    }

    changeModalEmail = (e) => {
        this.setState({
            modalEmail: e.target.value,
        })
    }

    addShareEmail = () => {
        var emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(this.state.modalEmail)) {
            this.setState({
                warning: {
                    message: "Invalid email for sharing design.",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                },
                addOrganiserProcess: false,
            })
        } else if (!this.state.shareEmailProcessing) {
            this.setState({
                shareEmailProcessing: true,
            })
            let shared_users = 2;
            switch (this.state.running_plan) {
                case 'pro':
                    shared_users = 4;
                    break;
                case 'business':
                    shared_users = 8;
                    break;
                case 'enterprise':
                    shared_users = 10;
                    break;
                default:
                    shared_users = 2;
                    break;
            }
            if (this.state.modal_shared_to === null || this.state.modal_shared_to.length < shared_users) {
                if (this.state.savedTemplates.filter(item => item.save_id === this.state.modal_save_id && item.shared_to !== null && item.shared_to.includes(this.state.modalEmail)).length > 0 || this.props.email === this.state.modalEmail) {
                    this.setState({
                        warning: {
                            message: "Already shared with organiser.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        shareEmailProcessing: false,
                    })
                } else {
                    axios.post(this.props.node_server_axios + 'share_saved_templates', {
                        params: {
                            email: this.props.email,
                            save_id: this.state.modal_save_id,
                            shared_to: this.state.modalEmail,
                        },
                    }).then(async (res) => {
                        if (res.data !== undefined) {
                            if (res.data.message === 'shared') {
                                this.setState({
                                    savedTemplates: res.data.saved_templates,
                                    warning: {
                                        message: "Access granted to " + this.state.modalEmail + ", You may share the link with the user.",
                                        type: "success",
                                        learnmore: false,
                                        autoclose: true,
                                    },
                                    shareModal: false,
                                    shareEmailProcessing: false,
                                })
                            }
                        } else {
                            this.setState({
                                shareEmailProcessing: false,
                            })
                        }
                    })
                }
            } else {
                this.setState({
                    warning: {
                        message: this.state.running_plan.toUpperCase() + " Plan only supports only " + shared_users + " shared users. Upgrage to get more.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    shareEmailProcessing: false,
                })
            }
        }
    }

    deleteSharedTo = (shared_to) => {
        axios.post(this.props.node_server_axios + 'delete_shared_to', {
            params: {
                email: this.props.email,
                save_id: this.state.modal_save_id,
                shared_to: shared_to,
            },
        }).then(async (res) => {
            if (res.data !== undefined) {
                console.log(res.data.message);
                if (res.data.message === 'deleted') {
                    this.setState({
                        savedTemplates: res.data.saved_templates,
                        warning: {
                            message: "Removed " + shared_to + ".",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        shareModal: false,
                        modal_shared_to: this.state.modal_shared_to.filter(element => element !== shared_to),
                    })
                }
            }
        })
    }

    copyUrl = (event, save_id) => {
        let url = window.location.href;
        const textToCopy = url.substring(0, url.lastIndexOf('/') + 1) + "edit/" + save_id;
        event.preventDefault();

        const textarea = document.createElement("textarea");
        textarea.value = textToCopy;
        textarea.setAttribute("readonly", "");
        textarea.style.position = "absolute";
        textarea.style.left = "-9999px";
        document.body.appendChild(textarea);

        textarea.select();
        document.execCommand("copy");

        document.body.removeChild(textarea);

        this.setState({
            warning: {
                message: "URL Copied.",
                type: "success",
                learnmore: false,
                autoclose: true,
            },
        })
    };

    editMode = (save_id) => {
        let url = window.location.href;
        window.location.href = url.substring(0, url.lastIndexOf('/') + 1) + "edit/" + save_id
    }

    render() {
        return (
            <React.Fragment>
                <div className="element">
                    <div className="container">
                        <div className='header'>
                            <div className='container-left'>
                                <h1>My Designs<label>View all your designs and templates</label></h1>
                                <button className='more-action-btn' onClick={this.toggleMore}>{this.state.moreBtn} <i className="fa-solid fa-ellipsis-vertical"></i></button>
                            </div>
                            {/* <button className='btn-issue' onClick={this.checkEligibility}><i className="fa-solid fa-stamp"></i> Create Certificate</button> */}
                        </div>
                        <div className='tiles marketplace'>
                            {this.state.shareModal && (
                                <div className='element-modal'>
                                    <div className='content'>
                                        <button className='close-btn' onClick={() => this.toggleSharePanel('', '')}><i className="fa-solid fa-x"></i></button>
                                        <div className='container'>
                                            <h1>Shared</h1>
                                        </div>
                                        <div className='container'>
                                            <input type='text' placeholder='Email' value={this.state.modalEmail} onChange={(e) => this.changeModalEmail(e)} maxLength='120' />
                                            <button onClick={this.addShareEmail}>Share</button>
                                        </div>
                                        <br></br>
                                        <h1>People with access</h1>
                                        <div className='container'>
                                            <p>{this.props.email}</p>
                                            <p>(owner)</p>
                                        </div>
                                        {this.state.modal_shared_to !== null && (
                                            this.state.modal_shared_to.map((element, index) =>
                                                <div className='container' key={index}>
                                                    <p>{element}</p>
                                                    <button className='del-btn' onClick={() => this.deleteSharedTo(element)}><i className="fa-regular fa-trash-can"></i> remove</button>
                                                </div>
                                            )
                                        )}
                                        <div className='share-modal-copy-container'>
                                            <p>Copy and share the link to added users</p>
                                            <button onClick={(e) => this.copyUrl(e, this.state.modal_save_id)}><i className="fa-solid fa-link"></i> Copy</button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className='sub-nav'>
                                <h1><i className="fa-solid fa-compass-drafting"></i> Draft</h1>
                                <div className='template-tile'>
                                    {this.state.certTemplate !== null ?
                                        <img src={this.state.certTemplate.includes("_") ? 'https://pronetin-new.s3.ap-south-1.amazonaws.com/templates/ai/background/' + this.state.certTemplate + '.png' : 'https://pronetin-new.s3.ap-south-1.amazonaws.com/templates/certificates/background/' + this.state.certTemplate + '.png'} alt='certificate template'></img>
                                        :
                                        <div className='content center'>
                                            <p>No draft available</p>
                                        </div>
                                    }
                                    <a href='/issue' className='continue-edit'>Edit <i className="fa-solid fa-pen-to-square"></i></a>
                                </div>
                                <br></br>
                                <p>{this.state.savedTemplates !== null ? this.state.savedTemplates.length : 0}/{this.state.save_slots} Saved slots Used</p>
                                <button onClick={this.saveAsTemplate}>Save Draft <i className="fa-regular fa-floppy-disk"></i></button>
                                {/* <p><i className="fa-solid fa-circle-info"></i> Last edited on 14/11/2022 17:31</p> */}
                                <div className='flex-grow'></div>
                                <div className='plan-container'>
                                    <h1 className='capitalize'>{this.state.running_plan.replace("-y", "")} Plan</h1>
                                    <button onClick={() => { window.location = './premium' }}>Upgrade <i className="fa-solid fa-square-plus"></i></button>
                                    <p>{this.state.running_plan.includes("-y") ? "Yearly Subscription" : "Monthly Subscription"}</p>
                                </div>
                            </div>
                            <div className='wrapper'>
                                <p><i className="fa-solid fa-clock-rotate-left"></i> Saved Designs</p>
                                <div className='template-wrapper'>
                                    {this.state.savedTemplates !== null && this.state.savedTemplates.length > 0 ?
                                        this.state.savedTemplates.map((element, index) =>
                                            <div className='template-tile' key={index}>
                                                <img src={element.draft_bg.includes("_") ? 'https://pronetin-new.s3.ap-south-1.amazonaws.com/templates/ai/background/' + element.draft_bg + '.png' : 'https://pronetin-new.s3.ap-south-1.amazonaws.com/templates/certificates/background/' + element.draft_bg + '.png'} alt='certificate template'></img>
                                                <div className='content'>
                                                    <div className='container'>
                                                        <button className='del-btn' onClick={() => this.deleteSavedTemplate(element.save_id)}><i className="fa-solid fa-trash"></i> Delete</button>
                                                        <button onClick={() => this.toggleSharePanel(element.shared_to, element.save_id)}><i className="fa-solid fa-share-nodes"></i> Share</button>
                                                    </div>
                                                    <div className='container'>
                                                        <button onClick={() => this.editMode(element.save_id)}><i className="fa-regular fa-edit"></i> Edit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                        :
                                        <div className='template-tile'>
                                            <div className='content center'>
                                                <p>No saved templates</p>
                                            </div>
                                        </div>
                                    }
                                </div>

                                {/* <p><i className="fa-solid fa-cloud-arrow-up"></i> Issued Designs</p> */}
                                {/* <div className='template-wrapper'>
                                    {this.state.issuedTemplates !== null && this.state.issuedTemplates.length > 0 ?
                                        this.state.issuedTemplates.map((element, index) =>
                                            <div className='template-tile' key={index}>
                                                <img src={'https://pronetin-new.s3.amazonaws.com/batch/' + this.props.org_id + '/' + element.cert_bg} alt='certificate template'></img>
                                                <div className='content blur'>
                                                    <h2>{element.cert_title}</h2>
                                                    <p>Event : {element.event_name}</p>
                                                    <p>Subevent : {element.sub_event_name}</p>
                                                </div>
                                                <button className='edit-btn'>Reuse <i className="fa-solid fa-repeat"></i></button>
                                            </div>
                                        )
                                        :
                                        <div className='template-tile'>
                                            <div className='content center'>
                                                <p>No issued designs</p>
                                            </div>
                                        </div>
                                    }
                                </div> */}
                            </div>
                            {
                                this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                            }
                        </div>
                    </div>
                </div>
                {
                    this.state.warning_action.message !== "" ? <WarningAction data={this.state.warning_action} fetchWarningActionState={this.fetchWarningActionState} resumeFunction={this.resumeFunction} /> : ""
                }
            </React.Fragment>
        );
    }
}

export default MyDesignComponent;