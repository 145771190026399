import React from 'react';
import './css/element.css';
import axios from 'axios';

import Warning from '../components/warning';
import sampleCertificate from '../images/sampleCertificate.png'
import ViewSignee from './viewer/signee';

import WarningAction from '../components/warning_action';
import ProgressLoader from '../components/progress_loader';

import tutorialVideo from '../images/videos/Signing Authority Video.mp4';
import videoThumbnail from '../images/videos/thumbnail.png';

class ProfileComponent extends React.Component {
    constructor(props) {
        super(props);
        this.fetchWarningState.bind(this);
        this.state = {
            document_count: 0,
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            warning_action: {
                message: "",
                subtext: "",
                type: "warning",
                proceed_action: false,
                proceed_function: false,
                back_action: false,
                close: true,
            },
            approval_list: [],
            uniqueEvents: [],
            filteredEvent: false,
            filteredEventValue: '',
            recepientData: [],
            recepientDataToggle: false,
            templatePreviewModal: false,
            previewImage: null,
            tutorial: true,
            moreBtn: 'options',
            elements: [],
            checkboxStates: {},
            progress_loader: false,
            tutorialVideo: true,
            previewEvent: '',
            doNotShowChecked: false,
        };
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    fetchWarningActionState = (data) => {
        this.setState({
            warning_action: {
                message: data,
            }
        })
    }

    toggleMore = () => {
        var subNav = document.getElementsByClassName('sub-nav')[0];
        if (subNav.classList.contains('show')) {
            subNav.classList.remove('show');
            subNav.classList.add('hide');
            this.setState({
                moreBtn: 'options',
            });
        } else {
            subNav.classList.add('show');
            subNav.classList.remove('hide');
            this.setState({
                moreBtn: 'back',
            });
        }
    }

    // checkEligibility = () => {
    //     if (this.props.user_type !== "organisation") {
    //         this.setState({
    //             warning: {
    //                 message: "Sorry, You need an organisation account to issue certificates.",
    //                 type: "warning",
    //                 learnmore: "create",
    //                 autoclose: false,
    //             }
    //         })
    //     } else {
    //         window.location = "/issue";
    //     }
    // }

    dummyActionBatch = async (status) => {
        if (this.state.current_signature) {
            if (status === 'accept') {
                this.setState({
                    warning: {
                        message: "Certificate batch approved.",
                        type: "success",
                        learnmore: false,
                        autoclose: false,
                    },
                    tutorial: false,
                })
            } else {
                this.setState({
                    warning: {
                        message: "Certificate batch rejected.",
                        type: "warning",
                        learnmore: false,
                        autoclose: false,
                    },
                    tutorial: false,
                })
            }
        } else {
            //--------------------------Warning Action Trigger Start------------------------------
            let proceed_function = true;
            this.setState({
                warning_action: {
                    message: "Signature not found",
                    subtext: "Upload your signature to sign & approve certificates",
                    type: "success",
                    proceed_action: "/mysignature",
                    proceed_function: false,
                    back_action: false,
                    close: false,
                    proceed_btn_text: 'Upload',
                }
            })
            if (proceed_function) {
                await new Promise((resolve) => {
                    this.resumeFunction = resolve;
                });
                this.setState({
                    warning_action: {
                        message: "",
                        subtext: "",
                        type: "warning",
                        proceed_action: false,
                        proceed_function: false,
                        back_action: false,
                        close: false,
                        proceed_btn_text: false,
                    },
                })
            }
            //--------------------------Warning Action Trigger End------------------------------
        }
    }

    acceptBatch = async (batchId, orgId, orgName) => {
        if (this.state.current_signature) {
            //--------------------------Warning Action Trigger Start------------------------------
            let proceed_function = true;
            this.setState({
                warning_action: {
                    message: "Sign Batch",
                    subtext: "This batch from " + orgName + " will be signed. Do you wish to continue?",
                    type: "success",
                    proceed_action: "true",
                    proceed_function: proceed_function,
                    back_action: false,
                    close: false,
                }
            })
            if (proceed_function) {
                await new Promise((resolve) => {
                    this.resumeFunction = resolve;
                });
                this.setState({
                    warning_action: {
                        message: "",
                        subtext: "",
                        type: "warning",
                        proceed_action: false,
                        proceed_function: false,
                        back_action: false,
                        close: false,
                    },
                })
            }
            //--------------------------Warning Action Trigger End------------------------------
            this.setState({
                progress_loader: true,
            });
            axios.defaults.withCredentials = true;
            axios.post(this.props.node_server_axios + 'sign_batch', {
                params: {
                    email: this.props.email,
                    batch_id: batchId,
                    updated_approval_list: this.state.approval_list.filter(cert => JSON.parse(cert).batch_id !== batchId),
                    org_id: orgId,
                    org_name: orgName,
                },
            }).then(async res => {
                if (res.data.message === 'success') {
                    this.setState({
                        approval_list: this.state.approval_list.filter(cert => JSON.parse(cert).batch_id !== batchId),
                        uniqueEvents: [...new Set(this.state.approval_list.map(cert => JSON.parse(cert).event))],
                        warning: {
                            message: "Certificate batch approved.",
                            type: "success",
                            learnmore: false,
                            autoclose: false,
                        },
                        progress_loader: false,
                    })
                    //--------------------------Warning Action Trigger Start------------------------------
                    let proceed_function = true;
                    this.setState({
                        warning_action: {
                            message: "Approval Successfull",
                            subtext: "You can proceed to see history of approvals",
                            type: "success",
                            proceed_action: "./approvalhistory",
                            proceed_function: false,
                            back_action: false,
                            close: true,
                            proceed_btn_text: 'Approval History',
                        }
                    })
                    if (proceed_function) {
                        await new Promise((resolve) => {
                            this.resumeFunction = resolve;
                        });
                        this.setState({
                            warning_action: {
                                message: "",
                                subtext: "",
                                type: "warning",
                                proceed_action: false,
                                proceed_function: false,
                                back_action: false,
                                close: false,
                                proceed_btn_text: false,
                            },
                        })
                    }
                    //--------------------------Warning Action Trigger End------------------------------
                } else if (res.data.message === 'no signature') {
                    this.setState({
                        warning: {
                            message: "No signature found to issue this certificate.",
                            type: "warning",
                            learnmore: 'mysignature',
                            autoclose: false,
                        },
                        progress_loader: false,
                    })
                } else {
                    this.setState({
                        approval_list: this.state.approval_list.filter(cert => JSON.parse(cert).batch_id !== batchId),
                        uniqueEvents: [...new Set(this.state.approval_list.map(cert => JSON.parse(cert).event))],
                        warning: {
                            message: "This Batch was removed by organiser.",
                            type: "warning",
                            learnmore: false,
                            autoclose: false,
                        },
                        progress_loader: false,
                    })
                }
            });
        } else {
            //--------------------------Warning Action Trigger Start------------------------------
            let proceed_function = true;
            this.setState({
                warning_action: {
                    message: "Signature not found",
                    subtext: "Upload your signature to sign & approve certificates",
                    type: "success",
                    proceed_action: "/mysignature",
                    proceed_function: false,
                    back_action: false,
                    close: false,
                    proceed_btn_text: 'Upload',
                }
            })
            if (proceed_function) {
                await new Promise((resolve) => {
                    this.resumeFunction = resolve;
                });
                this.setState({
                    warning_action: {
                        message: "",
                        subtext: "",
                        type: "warning",
                        proceed_action: false,
                        proceed_function: false,
                        back_action: false,
                        close: false,
                        proceed_btn_text: false,
                    },
                })
            }
            //--------------------------Warning Action Trigger End------------------------------
        }
    }

    rejectBatch = async (batchId, orgId, orgName) => {
        //--------------------------Warning Action Trigger Start------------------------------
        let proceed_function = true;
        this.setState({
            warning_action: {
                message: "Reject Batch",
                subtext: "This batch from " + orgName + " will be rejected. Do you wish to continue?",
                type: "warning",
                proceed_action: "true",
                proceed_function: proceed_function,
                back_action: false,
                close: false,
            }
        })
        if (proceed_function) {
            await new Promise((resolve) => {
                this.resumeFunction = resolve;
            });
            this.setState({
                warning_action: {
                    message: "",
                    subtext: "",
                    type: "warning",
                    proceed_action: false,
                    proceed_function: false,
                    back_action: false,
                    close: false,
                },
            })
        }
        //--------------------------Warning Action Trigger End------------------------------
        this.setState({
            progress_loader: true,
        });
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'reject_batch', {
            params: {
                email: this.props.email,
                batch_id: batchId,
                updated_approval_list: this.state.approval_list.filter(cert => JSON.parse(cert).batch_id !== batchId),
                org_id: orgId,
                org_name: orgName,
            },
        }).then(res => {
            if (res.data.message === 'success') {
                this.setState({
                    approval_list: this.state.approval_list.filter(cert => JSON.parse(cert).batch_id !== batchId),
                    uniqueEvents: [...new Set(this.state.approval_list.map(cert => JSON.parse(cert).event))],
                    warning: {
                        message: "Certificate batch rejected.",
                        type: "warning",
                        learnmore: false,
                        autoclose: false,
                    },
                    progress_loader: false,
                })
            } else {
                this.setState({
                    approval_list: this.state.approval_list.filter(cert => JSON.parse(cert).batch_id !== batchId),
                    uniqueEvents: [...new Set(this.state.approval_list.map(cert => JSON.parse(cert).event))],
                    warning: {
                        message: "This Batch was removed by organiser.",
                        type: "warning",
                        learnmore: false,
                        autoclose: false,
                    },
                    progress_loader: false,
                })
            }
        });
    }

    showRecepients = (batchId, orgId) => {
        this.setState({
            progress_loader: true,
        });
        axios.post(this.props.node_server_axios + 'get_recepient_list', {
            params: {
                batch_id: batchId,
                org_id: orgId,
            },
        }).then(res => {
            if (res.data.message === 'success') {
                this.setState({
                    recepientData: JSON.parse(res.data.csvData[0].csv_data),
                    recepientDataToggle: true,
                    progress_loader: false,
                })
            } else {
                this.setState({
                    warning: {
                        message: "Sorry, An error has occoured.",
                        type: "warning",
                        learnmore: false,
                        autoclose: false,
                    },
                    progress_loader: false,
                })
            }
        });
    }

    toggleAllApprovals = (e) => {
        e.preventDefault();
        this.setState({
            recepientData: [],
            recepientDataToggle: false,
        })
    }

    filterEvent = (e, element) => {
        e.preventDefault();
        this.setState({
            filteredEventValue: element,
            filteredEvent: true,
        })
        if (window.innerWidth < 820) {
            var subNav = document.getElementsByClassName('sub-nav')[0];
            subNav.classList.remove('show');
            subNav.classList.add('hide');
            this.setState({
                moreBtn: 'options',
            });
        }
    }

    clearFilter = () => {
        this.setState({
            filteredEventValue: '',
            filteredEvent: false,
        })
    }

    openTemplatePreview = (e, batch_id, previewEvent) => {
        axios.post(this.props.node_server_axios + 'get_batch_data', {
            params: {
                batchId: batch_id,
            },
        }).then(res => {
            this.setState({
                elements: res.data.elements,
            })
        }).catch(err => { console.log(err) })
        this.setState({
            templatePreviewModal: true,
            previewImage: e.target.src,
            previewEvent: previewEvent,
        })
    }

    openSampleTemplatePreview = (e) => {
        this.setState({
            templatePreviewModal: true,
            previewImage: e.target.src,
            previewEvent: 'Sample Certificate',
        })
    }

    closeTemplatePreview = (e) => {
        if (e.target.classList.contains('batchcert-preview-modal')) {
            this.setState({
                templatePreviewModal: false,
                elements: [],
            })
        }
    }

    updateScale = () => {
        const divWidth = this.divRef.offsetWidth;
        const screenWidth = window.innerWidth - 40;
        const divHeight = this.divRef.offsetHeight;
        const screenHeight = window.innerHeight - 220;
        const heightFactor = screenHeight / divHeight;
        const widthFactor = screenWidth / divWidth;
        const scaleFactor = heightFactor < widthFactor ? heightFactor : widthFactor;
        console.log(scaleFactor);
        if (scaleFactor < 1) {
            this.setState({ scaleFactor });
        }
    };

    handleCheckboxChange = (batchId, orgId, orgName) => {
        this.setState((prevState) => {
            const newCheckedCount = Object.values(prevState.checkboxStates).filter(item => item.checked).length;;
            if (!prevState.checkboxStates[batchId]?.checked && newCheckedCount >= 10) {
                return {
                    warning: {
                        message: "Only 10 batches can be signed at a time.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                };
            }

            // Update the state
            return {
                checkboxStates: {
                    ...prevState.checkboxStates,
                    [batchId]: {
                        org_id: orgId,
                        org_name: orgName,
                        checked: !prevState.checkboxStates[batchId]?.checked
                    }
                }
            };
        });
    };

    handleSignAllClick = async () => {
        if (this.state.current_signature) {
            const checkedValues = [];
            for (const batchId in this.state.checkboxStates) {
                if (this.state.checkboxStates[batchId].checked) {
                    checkedValues.push({
                        batch_id: batchId,
                        org_id: this.state.checkboxStates[batchId].org_id,
                        org_name: this.state.checkboxStates[batchId].org_name
                    });
                }
            }

            let temp_approval_list = this.state.approval_list;

            //--------------------------Warning Action Trigger Start------------------------------
            let proceed_function = true;
            this.setState({
                warning_action: {
                    message: "Sign selected batches",
                    subtext: "All selected batches will be signed. Do you wish to continue?",
                    type: "success",
                    proceed_action: "true",
                    proceed_function: proceed_function,
                    back_action: false,
                    close: false,
                }
            })
            if (proceed_function) {
                await new Promise((resolve) => {
                    this.resumeFunction = resolve;
                });
                this.setState({
                    warning_action: {
                        message: "",
                        subtext: "",
                        type: "warning",
                        proceed_action: false,
                        proceed_function: false,
                        back_action: false,
                        close: false,
                    },
                })
            }
            //--------------------------Warning Action Trigger End------------------------------
            this.setState({
                progress_loader: true,
            });

            const promises = checkedValues.map(async (element, index) => {
                try {
                    await new Promise((resolve) => setTimeout(resolve, 1000 * index));
                    temp_approval_list = temp_approval_list.filter(cert => JSON.parse(cert).batch_id !== element.batch_id);
                    const response = await axios.post(this.props.node_server_axios + 'sign_batch', {
                        params: {
                            email: this.props.email,
                            batch_id: element.batch_id,
                            updated_approval_list: temp_approval_list.length > 0 ? temp_approval_list : null,
                            org_id: element.org_id,
                            org_name: element.org_name,
                        },
                    });
                    temp_approval_list = temp_approval_list.length > 0 ? temp_approval_list : [];
                    return response.data.message;
                } catch (error) {
                    console.error(`Error for batch_id ${element.batch_id}:`, error);
                }
            });

            try {
                await Promise.all(promises);
                this.setState({
                    approval_list: temp_approval_list,
                    uniqueEvents: temp_approval_list.length > 0 ? [...new Set(temp_approval_list.map(cert => JSON.parse(cert).event))] : [],
                    progress_loader: false,
                    warning: {
                        message: "Multiple batches signed sucessfully.",
                        type: "success",
                        learnmore: false,
                        autoclose: true,
                    },
                })
                //--------------------------Warning Action Trigger Start------------------------------
                let proceed_function = true;
                this.setState({
                    warning_action: {
                        message: "Approval Successfull",
                        subtext: "You can proceed to see history of approvals",
                        type: "success",
                        proceed_action: "./approvalhistory",
                        proceed_function: false,
                        back_action: false,
                        close: true,
                        proceed_btn_text: 'Approval History',
                    }
                })
                if (proceed_function) {
                    await new Promise((resolve) => {
                        this.resumeFunction = resolve;
                    });
                    this.setState({
                        warning_action: {
                            message: "",
                            subtext: "",
                            type: "warning",
                            proceed_action: false,
                            proceed_function: false,
                            back_action: false,
                            close: false,
                            proceed_btn_text: false,
                        },
                    })
                }
                //--------------------------Warning Action Trigger End------------------------------
            } catch (error) {
                console.log(error);
                this.setState({
                    warning: {
                        message: "An Error has occoured.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    progress_loader: false,
                })
            }
        } else {
            //--------------------------Warning Action Trigger Start------------------------------
            let proceed_function = true;
            this.setState({
                warning_action: {
                    message: "Signature not found",
                    subtext: "Upload your signature to sign & approve certificates",
                    type: "success",
                    proceed_action: "./mysignature",
                    proceed_function: false,
                    back_action: false,
                    close: false,
                    proceed_btn_text: 'Upload',
                }
            })
            if (proceed_function) {
                await new Promise((resolve) => {
                    this.resumeFunction = resolve;
                });
                this.setState({
                    warning_action: {
                        message: "",
                        subtext: "",
                        type: "warning",
                        proceed_action: false,
                        proceed_function: false,
                        back_action: false,
                        close: false,
                        proceed_btn_text: false,
                    },
                })
            }
            //--------------------------Warning Action Trigger End------------------------------
        }
    };

    handleRejectAllClick = async () => {
        const checkedValues = [];
        for (const batchId in this.state.checkboxStates) {
            if (this.state.checkboxStates[batchId].checked) {
                checkedValues.push({
                    batch_id: batchId,
                    org_id: this.state.checkboxStates[batchId].org_id,
                    org_name: this.state.checkboxStates[batchId].org_name
                });
            }
        }

        let temp_approval_list = this.state.approval_list;

        //--------------------------Warning Action Trigger Start------------------------------
        let proceed_function = true;
        this.setState({
            warning_action: {
                message: "Reject selected batches",
                subtext: "All selected batches will be rejected. Do you wish to continue?",
                type: "warning",
                proceed_action: "true",
                proceed_function: proceed_function,
                back_action: false,
                close: false,
            }
        })
        if (proceed_function) {
            await new Promise((resolve) => {
                this.resumeFunction = resolve;
            });
            this.setState({
                warning_action: {
                    message: "",
                    subtext: "",
                    type: "warning",
                    proceed_action: false,
                    proceed_function: false,
                    back_action: false,
                    close: false,
                },
            })
        }
        //--------------------------Warning Action Trigger End------------------------------
        this.setState({
            progress_loader: true,
        });

        const promises = checkedValues.map(async (element, index) => {
            try {
                await new Promise((resolve) => setTimeout(resolve, 1000 * index));
                temp_approval_list = temp_approval_list.filter(cert => JSON.parse(cert).batch_id !== element.batch_id);
                const response = await axios.post(this.props.node_server_axios + 'reject_batch', {
                    params: {
                        email: this.props.email,
                        batch_id: element.batch_id,
                        updated_approval_list: temp_approval_list.length > 0 ? temp_approval_list : null,
                        org_id: element.org_id,
                        org_name: element.org_name,
                    },
                });
                temp_approval_list = temp_approval_list.length > 0 ? temp_approval_list : [];
                return response.data.message;
            } catch (error) {
                console.error(`Error for batch_id ${element.batch_id}:`, error);
            }
        });

        try {
            await Promise.all(promises);
            this.setState({
                approval_list: temp_approval_list,
                uniqueEvents: temp_approval_list.length > 0 ? [...new Set(temp_approval_list.map(cert => JSON.parse(cert).event))] : [],
                progress_loader: false,
                warning: {
                    message: "Multiple batches rejected.",
                    type: "success",
                    learnmore: false,
                    autoclose: true,
                },
            })
        } catch (error) {
            this.setState({
                warning: {
                    message: "An Error has occoured.",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                },
                progress_loader: false,
            })
        }
    };

    skipVideoTutorial = () => {
        this.setState({
            tutorialVideo: false,
        })
        if (this.state.doNotShowChecked) {
            localStorage.setItem('tutorialVideo_mysignature', true);
        }
    }

    componentDidMount = () => {
        const storedData = localStorage.getItem('tutorialVideo_mysignature');
        if (storedData) {
            this.setState({ tutorialVideo: false });
        }

        this.getApprovalHistory(1);
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'get_signature', {
            params: {
                email: this.props.email,
            },
        }).then(res => {
            this.setState({
                current_signature: res.data.signature,
            })
        });

        const videoElement = document.querySelector('.tutorial-video-modal video');
        if (videoElement) {
            videoElement.addEventListener('play', this.handleVideoPlay);
        }
    }

    componentWillUnmount() {
        const videoElement = document.querySelector('.tutorial-video-modal video');
        if (videoElement) {
            videoElement.removeEventListener('play', this.handleVideoPlay);
        }
    }

    handleVideoPlay = () => {
        //Delay timer for skip tutorial
        setTimeout(() => {
            const skipBtn = document.querySelector('.skip-btn');
            if (skipBtn) {
                skipBtn.style.display = 'flex';
                setTimeout(() => {
                    skipBtn.style.opacity = '1';
                }, 50);
            }
        }, 5000);
    }

    getApprovalHistory = (offset) => {
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'get_approvals_list', {
            params: {
                email: this.props.email,
                limit: 1,
            },
        }).then(res => {
            if (res.data.approval_list) {
                this.setState({
                    approval_list: res.data.approval_list,
                    uniqueEvents: [...new Set(res.data.approval_list.map(cert => JSON.parse(cert).event))],
                    // approval_history: res.data.approval_history,
                    // approval_history_count: parseInt(res.data.approval_history_count),
                    // approvalHistory_pageLimit: Math.ceil(parseInt(res.data.approval_history_count) / this.state.approvalHistory_batch),
                })
            }
            // } else if (res.data.approval_history) {
            //     this.setState({
            //         approval_history: res.data.approval_history,
            //         approval_history_count: parseInt(res.data.approval_history_count),
            //         approvalHistory_pageLimit: Math.ceil(parseInt(res.data.approval_history_count) / this.state.approvalHistory_batch),
            //     })
            // }
        });
    }

    doNotShowAgain = (e) => {
        this.setState({
            doNotShowChecked: e.target.checked,
        })
    }

    render() {
        const { scaleFactor } = this.state;
        const isAtLeastOneCheckboxChecked = Object.values(this.state.checkboxStates).some(
            (checkboxState) => checkboxState.checked
        );
        let signee_count = 0;

        return (
            <React.Fragment>
                <div className="element">
                    {this.state.templatePreviewModal && (
                        <div className='batchcert-preview-modal' onClick={this.closeTemplatePreview}>
                            <h3><label>Event Name</label>{this.state.previewEvent !== '' ? this.state.previewEvent : 'Sample Certificate'}</h3>
                            <div className='preview-rel' ref={ref => (this.divRef = ref)} style={{ transform: `scale(${scaleFactor})` }} onLoad={this.updateScale}>
                                <img src={this.state.previewImage} alt='certificate template' />
                                {
                                    this.state.elements && this.state.elements.length > 0 ?
                                        this.state.elements.map((element, index) => {
                                            if (element.type === "signature") {
                                                signee_count = signee_count + 1;
                                                return <ViewSignee element_id={"view-" + index} key={"view-" + element.timeStamp + "-" + index} data={element} node_server_axios={this.state.signeeStatus ? this.props.node_server_axios : undefined} batch_id={this.props.batchId} signee_id={signee_count} signee_name={element.content.split('|')[0] === this.props.email ? this.props.signee_name : 'Signee ' + signee_count} />
                                            } else {
                                                return null;
                                            }
                                        })
                                        :
                                        <div className='sign-loader'>
                                            Fetching Data...
                                        </div>
                                }
                            </div>
                        </div>
                    )}
                    {this.state.tutorialVideo && (
                        <div className='tutorial-video-modal'>
                            <video src={tutorialVideo} muted={false} onEnded={this.skipVideoTutorial} controls poster={videoThumbnail} />
                            <div className='skip-btn'>
                                <input type='checkbox' onChange={this.doNotShowAgain} checked={this.state.doNotShowChecked} />
                                <p>Do not show this again</p>
                                <button onClick={this.skipVideoTutorial}>Skip <i className="fa-solid fa-forward"></i></button>
                            </div>
                        </div>
                    )}
                    <div className="container">
                        <div className='header'>
                            <div className='container-left'>
                                <h1>Sign & Approvals<label>Sign certificates or documents</label></h1>
                                {/* <button className='more-action-btn' onClick={this.toggleMore}>{this.state.moreBtn} <i className="fa-solid fa-ellipsis-vertical"></i></button> */}
                            </div>
                            {/* <button className='btn-issue' onClick={this.checkEligibility}><i className="fa-solid fa-stamp"></i> Create Certificate</button> */}
                        </div>
                        <div className='tiles marketplace'>
                            <div className='sub-nav'>
                                <h1>Current Signature</h1>
                                {this.state.current_signature ?
                                    <div className='approvals-signature'>
                                        <img src={this.state.current_signature} className="user-signature" alt="user signature" />
                                        <a href='./mysignature'>Change Signature</a>
                                    </div>
                                    :
                                    <div className='approvals-signature'>
                                        <a href='./mysignature'>Upload Signature</a>
                                    </div>
                                }
                                <h1><i className="fa-solid fa-folder-tree"></i> Sort by Events</h1>
                                <div className='nav-container nav2-container'>
                                    {this.state.uniqueEvents.length > 0 && (
                                        this.state.uniqueEvents.map((element, index) =>
                                            <a className={this.state.filteredEventValue === element ? 'nav2 active' : 'nav2'} href="foo" key={index} onClick={(e) => { this.filterEvent(e, element) }}>
                                                {/* <i className="fa-regular fa-folder-open"></i> */}
                                                - {element}
                                            </a>
                                        )
                                    )}
                                </div>
                            </div>
                            {
                                this.state.recepientDataToggle ?
                                    <div className='settings wrapper'>
                                        <div className='subevent-head jc-sb sticky'>
                                            <a className='prev-btn' href='#foo' onClick={this.toggleAllApprovals}><i className="fa-solid fa-arrow-left"></i><p>Back</p></a>
                                            <div className='sub'>
                                                <h2>Recepients</h2>
                                                <p>{this.state.recepientData.length} certificates</p>
                                            </div>
                                        </div>
                                        <div className='table-scroller'>
                                            <table className='organiser-table'>
                                                <thead>
                                                    <tr>
                                                        {this.state.recepientData.length > 0 && Object.keys(this.state.recepientData[0]).map((key, index) => (
                                                            <th key={index}>{key.replace(/\[|\]/g, '')}</th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.recepientData.map((element, index) => (
                                                        <tr key={index}>
                                                            {Object.values(element).map((value, i) => (
                                                                <td key={i}>{value}</td>
                                                            ))}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    :
                                    <div className='approval-wrapper'>
                                        {this.state.current_signature ?
                                            <div className='approvals-signature'>
                                                <img src={this.state.current_signature} className="user-signature" alt="user signature" />
                                                <a href='./mysignature'>Change Signature</a>
                                            </div>
                                            :
                                            <div className='approvals-signature'>
                                                <a href='./mysignature'>Upload Signature</a>
                                            </div>
                                        }
                                        <h2>Approvals Requests</h2>
                                        {this.state.approval_list.length > 0 ?
                                            !this.state.filteredEvent ?
                                                this.state.approval_list.map((element, index) => {
                                                    element = JSON.parse(element);
                                                    return (
                                                        <div className='container approval-block-js' key={index}>
                                                            <input className='checkbox' type='checkbox' value={element.batch_id} checked={this.state.checkboxStates[element.batch_id]?.checked || false} onChange={() => this.handleCheckboxChange(element.batch_id, element.org_id, element.org_name)} />
                                                            {/* <div className='approval-icon'>
                                                                <i className="fa-solid fa-clipboard-check"></i>
                                                            </div> */}
                                                            <div className='certificate-preview'>
                                                                <img src={"https://pronetin-new.s3.amazonaws.com/batch/" + encodeURIComponent(element.org_id) + "/" + encodeURIComponent(element.preview.replace('.png', '_preview.png'))} alt="Batch Deleted by Admin / Organiser" onClick={(e) => this.openTemplatePreview(e, element.batch_id, element.event)} />
                                                            </div>
                                                            <div className='content-wrapper'>
                                                                <h2>{element.event}</h2>
                                                                <h3>{element.title}</h3>
                                                                <p>{element.date}</p>
                                                                <p>{element.org_name}</p>
                                                            </div>
                                                            <div className='flex-grow'></div>
                                                            <div className='actions'>
                                                                <div className='flex' onClick={() => { this.showRecepients(element.batch_id, element.org_id) }}>
                                                                    <div className='cert-count'>{element.cert_count} Certificate <i className="fa-solid fa-eye"></i></div>
                                                                    {/* <div className='emails'><i className="fa-solid fa-envelope"></i></div> */}
                                                                </div>
                                                                <div className='flex'>
                                                                    <button className='sign' onClick={() => { this.acceptBatch(element.batch_id, element.org_id, element.org_name) }}>Sign&nbsp;<i className="fa-solid fa-circle-check"></i></button>
                                                                    <button className='reject' onClick={() => { this.rejectBatch(element.batch_id, element.org_id, element.org_name) }}>Reject</button>
                                                                </div>
                                                            </div>
                                                        </div>)
                                                })
                                                :
                                                this.state.approval_list.filter(obj => JSON.parse(obj).event === this.state.filteredEventValue).map((element, index) => {
                                                    element = JSON.parse(element);
                                                    return (
                                                        <div className='container approval-block-js' key={index}>
                                                            {/* <div className='approval-icon'>
                                                                <i className="fa-solid fa-clipboard-check"></i>
                                                            </div> */}
                                                            <div className='certificate-preview'>
                                                                <img src={"https://pronetin-new.s3.amazonaws.com/batch/" + encodeURIComponent(element.org_id) + "/" + encodeURIComponent(element.preview.replace('.png', '_preview.png'))} alt="Batch Deleted by Admin / Organiser" onClick={(e) => this.openTemplatePreview(e, element.batch_id, element.event)} />
                                                            </div>
                                                            <div className='content-wrapper'>
                                                                <h2>{element.event}</h2>
                                                                <h3>{element.title}</h3>
                                                                <p>{element.date}</p>
                                                                <p>{element.org_name}</p>
                                                            </div>
                                                            <div className='flex-grow'></div>
                                                            <div className='actions'>
                                                                <div className='flex' onClick={() => { this.showRecepients(element.batch_id, element.org_id) }}>
                                                                    <div className='cert-count'>{element.cert_count} Certificate</div>
                                                                    {/* <div className='emails'><i className="fa-solid fa-envelope"></i></div> */}
                                                                </div>
                                                                <div className='flex'>
                                                                    <button className='sign' onClick={() => { this.acceptBatch(element.batch_id, element.org_id, element.org_name) }}>Sign <i className="fa-solid fa-circle-check"></i></button>
                                                                    <button className='reject' onClick={() => { this.rejectBatch(element.batch_id, element.org_id, element.org_name) }}>Reject</button>
                                                                </div>
                                                            </div>

                                                        </div>)
                                                })
                                            :
                                            this.state.tutorial ?
                                                <div className='container tutorial-opacity approval-block-js'>
                                                    {/* <div className='approval-icon'>
                                                        <i className="fa-solid fa-clipboard-check"></i>
                                                    </div> */}
                                                    <div className='certificate-preview'>
                                                        <img src={sampleCertificate} alt="Batch Deleted by Admin / Organiser" onClick={this.openSampleTemplatePreview} />
                                                    </div>
                                                    <div className='content-wrapper'>
                                                        <h2>Tutorial for Approvals</h2>
                                                        <h3>Event Name</h3>
                                                        <p>30/08/2023</p>
                                                        <p>Organisation Name</p>
                                                    </div>
                                                    <div className='flex-grow'></div>
                                                    <div className='actions'>
                                                        <div className='flex'>
                                                            <div className='cert-count'>120 Certificate</div>
                                                            {/* <div className='emails'><i className="fa-solid fa-envelope"></i></div> */}
                                                        </div>
                                                        <div className='flex'>
                                                            <button className='sign' onClick={() => { this.dummyActionBatch('accept') }}>Sign <i className="fa-solid fa-circle-check"></i></button>
                                                            <button className='reject' onClick={() => { this.dummyActionBatch('reject') }}>Reject</button>
                                                        </div>
                                                    </div>

                                                </div>
                                                :
                                                <p>No Approvals Pending</p>
                                        }
                                        {this.state.approval_list.length > 0 && isAtLeastOneCheckboxChecked && (
                                            <div className='group-action'>
                                                <button onClick={this.handleRejectAllClick}>Reject Selected</button>
                                                <button onClick={this.handleSignAllClick}>Sign Selected</button>
                                            </div>
                                        )}
                                        {
                                            this.state.filteredEvent && (<button onClick={this.clearFilter}>View All Events</button>)
                                        }
                                    </div>
                            }
                            {
                                this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                            }
                            {
                                this.state.warning_action.message !== "" ? <WarningAction data={this.state.warning_action} fetchWarningActionState={this.fetchWarningActionState} resumeFunction={this.resumeFunction} /> : ""
                            }
                        </div>
                    </div>
                </div>
                {
                    this.state.progress_loader && (<ProgressLoader />)
                }
            </React.Fragment>
        );
    }
}

export default ProfileComponent;