import React from 'react';
import Header from '../components/header';
import '../components/css/invoice.css';
import axios from 'axios';

import paperIcon from '../images/icons/paper.png';

class NotFoundComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            name: "",
            email: "",
            user_type: "user",
            logged_in: false,
            header_user_data: "",
        }
    }

    componentDidMount() {
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'session').then((res) => {
            if (res.data !== undefined && res.data.email !== undefined && res.data.email !== '') {
                this.setState({
                    loading: false,
                    name: res.data.name,
                    email: res.data.email,
                    user_type: res.data.user_type,
                    user_id: res.data.user_id,
                    storage: res.data.storage === null ? 10 : res.data.storage,
                    header_user_data: res.data,
                    logged_in: true,
                });
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="main no-margin overflow-auto public">
                    <Header login={this.state.logged_in} user_data={this.state.header_user_data} email={this.state.email} node_server_axios={this.props.node_server_axios} />
                    <div className='thank-you-modal'>
                        <h2>Certificate Not Found!</h2>
                        <h3>This certificate was <b>Rejected / Deleted</b> by the recepient</h3>
                        <img src={paperIcon} alt='certificate not found' />
                        <p>For further assistance you can mail us at <a href='mailto: help@pronetin.com' target="_blank" rel="noreferrer">help@pronetin.com</a></p>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default NotFoundComponent;