import Cropper from 'react-easy-crop'
import React, { useState, useCallback } from "react";
import axios from 'axios';

import ProgressLoader from '../../components/progress_loader';

const Demo = (props) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [data, setData] = useState(null);
    const [progress_loader, setProgressLoader] = useState(false)

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setData(croppedAreaPixels)
    }, [])

    const onUploadCrop = async () => {
        setProgressLoader(true);
        const upload_image = await resizeImage(props.signature_image, data.width, data.height, data.x, data.y, props.rotate)

        const sizeInBytes = getBase64Size(upload_image);
        const sizeInKB = sizeInBytes / 1024;

        if (sizeInKB < 500) {
            axios.defaults.withCredentials = true;
            axios.post(props.node_server_axios + 'upload_signature', {
                params: {
                    email: props.email,
                    signature: upload_image
                },
            }).then(res => {
                window.location.href = "/mysignature?o=uploaded";
            });
        } else {
            window.location.replace("/mysignature?o=toolarge");
        }
    }

    function getBase64Size(base64String) {
        const stringLength = base64String.length;
        const paddingLength = (base64String.match(/=+$/) || [''])[0].length;
        const byteLength = (stringLength - paddingLength) * 0.75 - paddingLength;
        return byteLength;
    }

    function resizeImage(url, cropWidth, cropHeight, cropX, cropY, rotation) {
        return new Promise((resolve, reject) => {
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            const imageObj = new Image();

            imageObj.onload = function () {
                const radians = (rotation * Math.PI) / 180;
                const cos = Math.cos(radians);
                const sin = Math.sin(radians);
                const rotatedWidth = Math.abs(imageObj.width * cos) + Math.abs(imageObj.height * sin);
                const rotatedHeight = Math.abs(imageObj.width * sin) + Math.abs(imageObj.height * cos);
                canvas.width = rotatedWidth;
                canvas.height = rotatedHeight;
                context.translate(rotatedWidth / 2, rotatedHeight / 2);
                context.rotate(radians);
                context.drawImage(
                    imageObj,
                    -imageObj.width / 2,
                    -imageObj.height / 2
                );
                context.setTransform(1, 0, 0, 1, 0, 0);
                const cropCanvas = document.createElement("canvas");
                cropCanvas.width = cropWidth;
                cropCanvas.height = cropHeight;
                const cropContext = cropCanvas.getContext("2d");

                cropContext.drawImage(
                    canvas,
                    cropX,
                    cropY,
                    cropWidth,
                    cropHeight,
                    0,
                    0,
                    cropWidth,
                    cropHeight
                );

                resolve(cropCanvas.toDataURL());
            };

            imageObj.onerror = function () {
                reject("Image failed to load");
            };

            imageObj.src = url;
        });
    }

    return (
        <div className='container'>
            <Cropper
                image={props.signature_image}
                crop={crop}
                zoom={props.zoom}
                rotation={props.rotate}
                aspect={16 / 9}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
            />
            <button onClick={onUploadCrop}>Upload Signature</button>
            {
                progress_loader && (<ProgressLoader />)
            }
        </div>
    )
}

export default Demo