import React from 'react';
import axios from 'axios';

import userImage from '../../images/defaultorganisation.png';

import Warning from '../warning';
import WarningAction from '../warning_action';

import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import countryCodes from '../../data/country_codes'

import ProgressLoader from '../../components/progress_loader';
import tutorialVideo from '../../images/videos/After Org Direct Export.mp4';
import videoThumbnail from '../../images/videos/thumbnail.png';

class DataComponent extends React.Component {
    constructor(props) {
        super(props);
        this.fetchWarningState.bind(this);
        this.cropperRef = React.createRef();
        this.state = {
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            warning_action: {
                message: "",
                subtext: "",
                type: "warning",
                proceed_action: false,
                proceed_function: false,
                back_action: false,
                close: true,
            },
            profileImageIcon: userImage,
            imagePreviewUrl: '',
            org_name: '',
            sector: '',
            phone: '',
            linkedin: '',
            adl1: '',
            adl2: '',
            country: '',
            pincode: '',
            domain: '',
            inviteeEmail: '',
            organisers: [],
            join_requests: [],
            noAccess: true,
            running_plan: 'free',
            progress_loader: false,
            transferModal: false,
            subOrganisationModal: false,
            subOrganisationName: '',
            transferTo: '',
            transferToValid: false,
            pass: '',
            passAuthModal: false,
            newHere: !localStorage.getItem('tutorialVideo_account_organisation') ? true : false,
            tutorialVideo: false,
            characterRemaining: 0,
            organisationAdmin: '',
            subOrganisations: [],
        };
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    fetchWarningActionState = (data) => {
        this.setState({
            warning_action: {
                message: data,
            }
        })
    }

    logFormattedDate(date) {
        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const day = daysOfWeek[date.getDay()];
        const dayOfMonth = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${dayOfMonth}-${day} (${hours}:${minutes})`;
    }

    componentDidMount() {
        this.setState({
            sectionLoaded: false,
        });
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'organisation_info', {
            params: {
                email: this.props.email,
                sanction: 'admin-email',
            },
        }).then(res => {
            if (res.data.length > 0 && res.data[0].org_id) {
                axios.post(this.props.node_server_axios + 'get_organisation_data', {
                    params: {
                        org_id: res.data[0].org_id,
                    }
                }).then((res) => {
                    if (res.data !== undefined && res.data.message === 'success') {
                        this.setState({
                            running_plan: res.data.running_plan,
                        });
                    } else {
                        window.location = "/signin";
                    }
                })
                if (res.data.length > 0) {
                    this.setState({
                        org_name: res.data[0].org_name,
                        sector: res.data[0].sector || '',
                        linkedin: res.data[0].linkedin || '',
                        phone: res.data[0].phone === null ? '' : res.data[0].phone.split("-").pop(),
                        selectedCountryCode: res.data[0].phone === null ? '' : res.data[0].phone.split("-")[0],
                        adl1: res.data[0].adl1 || '',
                        adl2: res.data[0].adl2 || '',
                        country: res.data[0].country || '',
                        pincode: res.data[0].pincode || '',
                        last_reset: res.data[0].last_reset,
                        domain: res.data[0].domain,
                        join_requests: res.data[0].join_requests,
                        organisers: res.data[0].organisers,
                        inviteeEmail: '',
                        verified: res.data[0].verified,
                        noAccess: false,
                        sectionLoaded: true,
                    })

                    fetch("https://pronetin-new.s3.amazonaws.com/organisation/" + res.data[0].domain + "/ogranisationLogo.png?cacheblock=" + Date.now())
                        .then((response) => {
                            if (response.ok) {
                                this.setState({
                                    organisationLogo: true,
                                    profileImageIcon: "https://pronetin-new.s3.amazonaws.com/organisation/" + res.data[0].domain + "/ogranisationLogo.png?cacheblock=" + Date.now(),
                                })
                            } else {
                                this.setState({
                                    organisationLogo: false,
                                })
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else {
                    window.location = '/account';
                }
                axios.post(this.props.node_server_axios + 'get_sub_organisation').then(async res => {
                    if (res.data.subOrganisations && res.data.subOrganisations.length > 0) {
                        this.setState({
                            subOrganisations: res.data.subOrganisations,
                        })
                    }
                });
            } else {
                if (this.props.user_type === 'organisation') {
                    this.setState({
                        noAccess: true,
                        sectionLoaded: true,
                        organisationAdmin: res.data,
                    })
                } else {
                    window.location = '/';
                }
            }
        });

        window.addEventListener('beforeunload', this.handleBeforeUnload);
    }

    handleVideoPlay = () => {
        //Delay timer for skip tutorial
        setTimeout(() => {
            const skipBtn = document.querySelector('.skip-btn');
            if (skipBtn) {
                skipBtn.style.display = 'flex';
                setTimeout(() => {
                    skipBtn.style.opacity = '1';
                }, 50);
            }
        }, 5000);
    }

    componentWillUnmount = () => {
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevState.noAccess !== this.state.noAccess && !this.state.noAccess && this.props.part) {
            const element = document.getElementById(this.props.part);
            console.log(element);
            if (element) {
                element.classList.add('pulse');
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    }

    handleBeforeUnload = (event) => {
        if (this.state.changesDetected) {
            event.preventDefault();
            const customMessage = 'Are you sure you want to leave? Your changes may not be saved.';
            event.returnValue = customMessage;
        }
    };

    toggleSharePanel = () => {
        this.setState({
            shareModal: !this.state.shareModal,
        })
    }

    handleImageChange = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        // check file type
        if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
            this.setState({
                warning: {
                    message: "Please select a JPG or PNG image file.",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                }
            });
            return;
        } else if (file.size / 1024 > 3000) {
            this.setState({
                warning: {
                    message: "Max file size allowed is 3Mb.",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                }
            });
            return;
        }

        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result,
            });
        };

        reader.readAsDataURL(file);
    };


    handleSubmit = async (e) => {
        this.setState({
            progress_loader: true,
        })
        e.preventDefault();
        let reader = new FileReader();
        const cropper = this.cropperRef.current.cropper;
        if (!cropper) {
            return;
        }

        const croppedCanvas = cropper.getCroppedCanvas();
        croppedCanvas.toBlob((blob) => {
            const croppedFile = new File([blob], this.state.domain + '.png', { type: 'image/png' });
            this.setState({ file: croppedFile });
            const file = croppedFile;
            reader.readAsDataURL(file);
            const formData = new FormData();
            formData.append('image', file);
            axios.post(this.props.node_server_axios + 'update_organisation_logo', formData
            ).then(res => {
                if (res.data.message === 'updated') {
                    //---------------------Logging Data (Organisation) Start---------------------
                    axios.post(this.props.node_server_axios + 'update_organisation_log', {
                        params: {
                            email: this.props.email,
                            dateTS: this.logFormattedDate(new Date()),
                            creditsConsumed: '0',
                            content: "Organisation logo updated",
                        },
                    })
                    //----------------------Logging Data (Organisation) End----------------------
                    this.setState((prevState) => ({
                        warning: {
                            message: "Organisation Logo Updated.",
                            type: "success",
                            learnmore: false,
                            autoclose: true,
                        },
                        shareModal: false,
                        refreshKey: prevState.refreshKey + 1,
                        profileImageIcon: reader.result,
                        progress_loader: false,
                    }))
                } else {
                    this.setState({
                        warning: {
                            message: "Sorry, An error has occoured.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        progress_loader: false,
                    })
                }
            });
        }, 'image/png');
    };

    changePhone = (e) => {
        this.setState({
            phone: e.target.value,
            changesDetected: true,
        })
    }
    changeName = (e) => {
        this.setState({
            org_name: e.target.value,
        })
    }
    changeSector = (e) => {
        this.setState({
            sector: e.target.value,
            changesDetected: true,
        })
    }
    changeAdl1 = (e) => {
        this.setState({
            adl1: e.target.value,
            changesDetected: true,
        })
    }
    changeAdl2 = (e) => {
        this.setState({
            adl2: e.target.value,
            changesDetected: true,
        })
    }
    changeCountry = (e) => {
        this.setState({
            country: e.target.value,
            changesDetected: true,
        })
    }
    changeLinkedin = (e) => {
        this.setState({
            linkedin: e.target.value,
            changesDetected: true,
        })
    }
    changePincode = (e) => {
        this.setState({
            pincode: e.target.value,
            changesDetected: true,
        })
    }
    handleCountryCodeChange = (e) => {
        this.setState({
            selectedCountryCode: e.target.value,
            changesDetected: true,
        });
    };
    changeInviteeEmail = (e) => {
        this.setState({
            inviteeEmail: e.target.value,
        })
    }

    updateProfile = () => {
        axios.post(this.props.node_server_axios + 'update_organisation_info', {
            params: {
                sector: this.state.sector,
                linkedin: this.state.linkedin,
                phone: this.state.selectedCountryCode + "-" + this.state.phone,
                adl1: this.state.adl1,
                adl2: this.state.adl2,
                country: this.state.country,
                pincode: this.state.pincode,
            },
        }).then(res => {
            if (res.data.message === 'updated') {
                //---------------------Logging Data (Organisation) Start---------------------
                axios.post(this.props.node_server_axios + 'update_organisation_log', {
                    params: {
                        email: this.props.email,
                        dateTS: this.logFormattedDate(new Date()),
                        creditsConsumed: '0',
                        content: "Organisation profile updated",
                    },
                })
                //----------------------Logging Data (Organisation) End----------------------
                this.setState({
                    warning: {
                        message: "Profile Updated.",
                        type: "success",
                        learnmore: false,
                        autoclose: true,
                    },
                    changesDetected: false,
                })
            } else {
                this.setState({
                    warning: {
                        message: "Sorry, An error has occoured.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                })
            }
        });
    }

    rejectJoinRequest = (request_email) => {
        this.setState({
            progress_loader: true,
        });
        axios.post(this.props.node_server_axios + 'reject_join_request', {
            params: {
                email: request_email,
            },
        }).then(res => {
            if (res.data.message === 'success') {
                //---------------------Logging Data (Organisation) Start---------------------
                axios.post(this.props.node_server_axios + 'update_organisation_log', {
                    params: {
                        email: this.props.email,
                        dateTS: this.logFormattedDate(new Date()),
                        creditsConsumed: '0',
                        content: "Join request from " + request_email + " was rejected",
                    },
                })
                //----------------------Logging Data (Organisation) End----------------------
                this.setState({
                    warning: {
                        message: request_email + " has been rejected.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    join_requests: this.state.join_requests.filter(element => element !== request_email),
                    progress_loader: false,
                })
                this.removeNotificationByAction(request_email + " would like to join");
            }
        });
    }

    acceptJoinRequest = (request_email) => {
        this.setState({
            progress_loader: true,
        });
        if (this.state.organisers !== null && this.state.organisers.includes(request_email)) {
            axios.post(this.props.node_server_axios + 'reject_join_request', {
                params: {
                    email: request_email,
                },
            }).then(res => {
                if (res.data.message === 'success') {
                    this.setState({
                        warning: {
                            message: request_email + " was already added to this organisation.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        join_requests: this.state.join_requests.filter(element => element !== request_email),
                        progress_loader: false,
                    })
                }
            });
        } else {
            axios.post(this.props.node_server_axios + 'accept_join_request', {
                params: {
                    email: request_email,
                },
            }).then(res => {
                console.log(res.data.message);
                if (res.data.message === 'success') {
                    //---------------------Logging Data (Organisation) Start---------------------
                    axios.post(this.props.node_server_axios + 'update_organisation_log', {
                        params: {
                            email: this.props.email,
                            dateTS: this.logFormattedDate(new Date()),
                            creditsConsumed: '0',
                            content: "Join request from " + request_email + " was accepted",
                        },
                    })
                    //----------------------Logging Data (Organisation) End----------------------
                    this.setState({
                        warning: {
                            message: request_email + " has been accepted.",
                            type: "success",
                            learnmore: false,
                            autoclose: true,
                        },
                        join_requests: this.state.join_requests.filter(element => element !== request_email),
                        organisers: this.state.organisers !== null && this.state.organisers !== '' ? this.state.organisers.push(request_email) : [request_email],
                        progress_loader: false,
                    })
                    this.removeNotificationByAction(request_email + " would like to join");
                } else {
                    this.setState({
                        warning: {
                            message: "This user cannot join your organisation.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        join_requests: this.state.join_requests.filter(element => element !== request_email),
                        progress_loader: false,
                    })
                }
            });
        }
    }

    requestInvitee = () => {
        this.setState({
            progress_loader: true,
        });
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailRegex.test(this.state.inviteeEmail)) {
            this.setState({
                warning: {
                    message: "Invalid organiser email.",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                },
                progress_loader: false,
            })
            return null;
        }
        axios.post(this.props.node_server_axios + 'request_invitee', {
            params: {
                email: this.state.inviteeEmail,
            },
        }).then(res => {
            if (res.data.message === 'success') {
                //---------------------Logging Data (Organisation) Start---------------------
                axios.post(this.props.node_server_axios + 'update_organisation_log', {
                    params: {
                        email: this.props.email,
                        dateTS: this.logFormattedDate(new Date()),
                        creditsConsumed: '0',
                        content: "Invitation request to join organisation has been send to " + this.state.inviteeEmail,
                    },
                })
                //----------------------Logging Data (Organisation) End----------------------
                this.setState({
                    warning: {
                        message: "Invitation to " + this.state.inviteeEmail + " has been send.",
                        type: "success",
                        learnmore: false,
                        autoclose: true,
                    },
                    inviteeEmail: '',
                    progress_loader: false,
                })
            } else if (res.data.message === 'user does not exist') {
                this.setState({
                    warning: {
                        message: "User does not exist.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    requestInviteModal: true,
                    progress_loader: false,
                })
            } else if (res.data.message === 'invitation already send') {
                this.setState({
                    warning: {
                        message: "Invitation already send to this user.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    progress_loader: false,
                })
            } else if (res.data.message === 'already in organisation') {
                this.setState({
                    warning: {
                        message: "User already part of an organisation.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    progress_loader: false,
                })
            } else if (res.data.message === 'user requested') {
                this.setState({
                    warning: {
                        message: "User has already requested to join this organisation.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    progress_loader: false,
                })
            } else {
                this.setState({
                    warning: {
                        message: "An error has occoured.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    progress_loader: false,
                })
            }
        });
    }

    deleteOrganiser = async (request_email) => {
        //--------------------------Warning Action Trigger Start------------------------------
        let proceed_function = true;
        this.setState({
            warning_action: {
                message: "Remove Organiser",
                subtext: request_email + " will loose all data assosiated with this organisation (eg: saved certificate designs), Are you sure you want to continue?",
                type: "warning",
                proceed_action: "true",
                proceed_function: proceed_function,
                back_action: false,
                close: true,
            }
        })
        if (proceed_function) {
            await new Promise((resolve) => {
                this.resumeFunction = resolve;
            });
            this.setState({
                warning_action: {
                    message: "",
                    subtext: "",
                    type: "warning",
                    proceed_action: false,
                    proceed_function: false,
                    back_action: false,
                    close: true,
                },
            })
        }
        //--------------------------Warning Action Trigger End------------------------------
        this.setState({
            progress_loader: true,
        });
        axios.post(this.props.node_server_axios + 'remove_organiser', {
            params: {
                email: request_email,
            },
        }).then(res => {
            if (res.data.message === 'success') {
                //---------------------Logging Data (Organisation) Start---------------------
                axios.post(this.props.node_server_axios + 'update_organisation_log', {
                    params: {
                        email: this.props.email,
                        dateTS: this.logFormattedDate(new Date()),
                        creditsConsumed: '0',
                        content: request_email + " has been removed from organisation",
                    },
                })
                //----------------------Logging Data (Organisation) End----------------------
                let updatedOrganisers = this.state.organisers;
                let index = updatedOrganisers.indexOf(request_email);
                if (index !== -1) {
                    updatedOrganisers.splice(index, 1);
                }
                this.setState({
                    warning: {
                        message: request_email + " has been removed.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    join_requests: this.state.join_requests !== null && this.state.join_requests !== '' ? this.state.join_requests.filter(element => element !== request_email) : [],
                    organisers: updatedOrganisers,
                    progress_loader: false,
                })
            }
        });
    }

    deleteSubOrganisation = (subOrg) => {
        this.setState({
            progress_loader: true,
        });
        axios.post(this.props.node_server_axios + 'remove_sub_organisation', {
            params: {
                subOrganisation: subOrg,
            },
        }).then(res => {
            if (res.data.message === 'success') {
                let updatedsubOrganisations = this.state.subOrganisations;
                let index = updatedsubOrganisations.indexOf(subOrg);
                if (index !== -1) {
                    updatedsubOrganisations.splice(index, 1);
                }
                this.setState({
                    warning: {
                        message: subOrg + " has been removed.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    subOrganisations: updatedsubOrganisations,
                    progress_loader: false,
                })
            }
        });
    }

    requestNameChange = () => {
        let currentDate = new Date();
        let year = currentDate.getFullYear().toString();
        let month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        let day = currentDate.getDate().toString().padStart(2, '0');
        let formattedDate = year + month + day;

        if (this.state.last_reset === null || parseInt(formattedDate) - parseInt(this.state.last_reset) > 90) {
            axios.post(this.props.node_server_axios + 'update_organisation_name', {
                params: {
                    org_name: this.state.org_name,
                    last_reset: formattedDate,
                },
            }).then(res => {
                if (res.data.message === 'updated') {
                    //---------------------Logging Data (Organisation) Start---------------------
                    axios.post(this.props.node_server_axios + 'update_organisation_log', {
                        params: {
                            email: this.props.email,
                            dateTS: this.logFormattedDate(new Date()),
                            creditsConsumed: '0',
                            content: "Organisation name updated to " + this.state.org_name,
                        },
                    })
                    //----------------------Logging Data (Organisation) End----------------------
                    this.setState({
                        warning: {
                            message: "Organisation Name Updated.",
                            type: "success",
                            learnmore: false,
                            autoclose: true,
                        }
                    })
                    this.props.fetchDataUpdate({ type: "org_name", value: this.state.org_name });
                } else {
                    this.setState({
                        warning: {
                            message: "Sorry, An error has occoured.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        }
                    })
                }
            });
        } else {
            this.setState({
                warning: {
                    message: "Sorry, Name can be change only after " + (90 - (parseInt(formattedDate) - parseInt(this.state.last_reset))) + " days.",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                }
            })
        }
    }


    togglePassAuthModal = (modal) => {
        this.setState({
            passAuthModal: !this.state.passAuthModal,
            actionModal: modal,
        })
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    updatePass = (e) => {
        this.setState({
            pass: e.target.value,
        })
    }

    authenticateLogin = () => {
        this.setState({
            progress_loader: true,
        })
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'authenticate_login', {
            params: {
                data: {
                    email: this.props.email.toLowerCase().replace(/\s+/g, ''),
                    password: this.state.pass,
                }
            },
        }).then(res => {
            if (res.data.message === 'success') {
                this.setState({
                    passAuth: true,
                    progress_loader: false,
                    pass: '',
                    passAuthModal: false,
                })
                if (this.state.actionModal === 'delete') {
                    this.deleteOrganisationPermanently();
                } else if (this.state.actionModal === 'transfer') {
                    this.setState({
                        transferModal: true
                    })
                }
            } else {
                this.setState({
                    warning: {
                        message: "Incorrect Password.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    pass: '',
                    progress_loader: false,
                })
            }
        });
    }

    deleteOrganisationPermanently = async () => {
        //--------------------------Warning Action Trigger Start------------------------------
        let proceed_function = true;
        this.setState({
            warning_action: {
                message: "Delete Organisation",
                subtext: "This action will delete your organisation account and its privilages, Are you sure you want to continue?",
                type: "warning",
                proceed_action: "true",
                proceed_function: proceed_function,
                back_action: false,
                close: true,
            }
        })
        if (proceed_function) {
            await new Promise((resolve) => {
                this.resumeFunction = resolve;
            });
            this.setState({
                warning_action: {
                    message: "",
                    subtext: "",
                    type: "warning",
                    proceed_action: false,
                    proceed_function: false,
                    back_action: false,
                    close: true,
                },
            })
        }
        //--------------------------Warning Action Trigger End------------------------------
        this.setState({
            progress_loader: true,
        })
        axios.post(this.props.node_server_axios + 'delete_organisation_permanently').then(async res => {
            if (res.data.message === 'organisation deleted') {
                await axios.post(this.props.node_server_axios + 'force_update_session');
                window.location = '/';
            } else {
                this.setState({
                    warning: {
                        message: "Sorry, An error has occoured.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    progress_loader: false,
                })
            }
        });
    }

    leaveOrganisation = async () => {
        //--------------------------Warning Action Trigger Start------------------------------
        let proceed_function = true;
        this.setState({
            warning_action: {
                message: "Leave Organisation",
                subtext: "This action will remove you from this organisation account and its privilages, Are you sure you want to continue?",
                type: "warning",
                proceed_action: "true",
                proceed_function: proceed_function,
                back_action: false,
                close: true,
            }
        })
        if (proceed_function) {
            await new Promise((resolve) => {
                this.resumeFunction = resolve;
            });
            this.setState({
                warning_action: {
                    message: "",
                    subtext: "",
                    type: "warning",
                    proceed_action: false,
                    proceed_function: false,
                    back_action: false,
                    close: true,
                },
            })
        }
        //--------------------------Warning Action Trigger End------------------------------
        this.setState({
            progress_loader: true,
        })
        axios.post(this.props.node_server_axios + 'leave_organisation_permanently').then(async res => {
            if (res.data.message === 'left organisation') {
                await axios.post(this.props.node_server_axios + 'force_update_session');
                window.location = '/account';
            } else {
                this.setState({
                    warning: {
                        message: "Sorry, An error has occoured.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    progress_loader: false,
                })
            }
        });
    }

    transferAdminPermanently = () => {
        this.setState({
            progress_loader: true,
        })
        if (this.state.transferTo !== this.props.email && this.state.transferToValid) {
            axios.post(this.props.node_server_axios + 'transfer_admin_permanently', {
                params: {
                    transferTo: this.state.transferTo,
                },
            }).then(async res => {
                if (res.data.message === 'user transfered') {
                    await axios.post(this.props.node_server_axios + 'force_update_session');
                    window.location = '/';
                } else if (res.data.message === 'user not found') {
                    this.setState({
                        warning: {
                            message: "No user found at this email address.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        progress_loader: false,
                    })
                } else if (res.data.message === 'user in organisation') {
                    if (this.state.organisers && this.state.organisers.includes(this.state.transferTo)) {
                        this.setState({
                            warning: {
                                message: "Remove " + this.state.transferTo + " from your organisation (go to Manage Organisers) to continue transfer.",
                                type: "warning",
                                learnmore: false,
                                autoclose: false,
                            },
                            progress_loader: false,
                        })
                    } else {
                        this.setState({
                            warning: {
                                message: "To transfer, the new owner must leave their current organization.",
                                type: "warning",
                                learnmore: false,
                                autoclose: false,
                            },
                            progress_loader: false,
                        })
                    }
                } else {
                    this.setState({
                        warning: {
                            message: "Sorry, An error has occoured.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        progress_loader: false,
                    })
                }
            });
        } else {
            this.setState({
                warning: {
                    message: "Cannot transfer to this email.",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                },
                progress_loader: false,
            })
        }
    }

    addSubOrganisation = () => {
        this.setState({
            progress_loader: true,
        })
        if (this.state.subOrganisations.length < 12) {
            if (this.state.subOrganisationName.length > 2) {
                if (!this.state.subOrganisations.includes(this.state.subOrganisationName)) {
                    axios.post(this.props.node_server_axios + 'add_sub_organisation', {
                        params: {
                            subOrganisationName: this.state.subOrganisationName,
                        },
                    }).then(async res => {
                        if (res.data.message === 'success') {
                            window.location = '/account?selection=organisation';
                        } else {
                            this.setState({
                                warning: {
                                    message: "Sorry, An error has occoured.",
                                    type: "warning",
                                    learnmore: false,
                                    autoclose: true,
                                },
                                progress_loader: false,
                            })
                        }
                    });
                } else {
                    this.setState({
                        warning: {
                            message: "Sub Organisation already exists.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        progress_loader: false,
                    })
                }
            } else {
                this.setState({
                    warning: {
                        message: "Failed to add sub organisation. (minimum 3 characters required)",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    progress_loader: false,
                })
            }
        } else {
            this.setState({
                warning: {
                    message: "Sub Organisation limit reached, Please remove existing to add new",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                },
                progress_loader: false,
            })
        }
    }

    toggleTransferModal = (e) => {
        this.setState({
            transferModal: !this.state.transferModal,
        })
    }

    toggleSubOrganisationModal = () => {
        this.setState({
            subOrganisationModal: !this.state.subOrganisationModal,
        })
    }

    updateSubOrganisationName = (e) => {
        const limit = 30;
        if (e.target.value.length <= limit) {
            this.setState({
                subOrganisationName: e.target.value,
                characterRemaining: limit - e.target.value.length,
            })
        }
    }

    updateTransferToEmail = (e) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        this.setState({
            transferTo: e.target.value,
            transferToValid: emailRegex.test(e.target.value),
        })
    }

    openVideoTutorial = () => {
        this.setState({ tutorialVideo: true }, () => {
            const videoElement = document.querySelector('.tutorial-video-modal video');
            if (videoElement) {
                videoElement.addEventListener('play', this.handleVideoPlay);
            }
        });
    }

    skipVideoTutorial = () => {
        this.setState({
            tutorialVideo: false,
        })
        localStorage.setItem('tutorialVideo_account_organisation', true);

        const videoElement = document.querySelector('.tutorial-video-modal video');
        if (videoElement) {
            videoElement.removeEventListener('play', this.handleVideoPlay);
        }
    }

    changePassword = () => {
        this.setState({
            progress_loader: true,
        })
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'forgot', {
            params: {
                data: {
                    email: this.props.email.toLowerCase().replace(/\s+/g, ''),
                }
            },
        }).then(res => {
            if (res.data.message === 'Check your inbox for reset email') {
                this.setState({
                    warning: {
                        message: "Link to reset password has been sent to your email.",
                        type: "success",
                        learnmore: false,
                        autoclose: true,
                    },
                    progress_loader: false,
                    reset_password: true,
                })
            } else {
                this.setState({
                    warning: {
                        message: "An Error has occoured.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    progress_loader: false,
                })
            }
        });
    }

    render() {
        let { imagePreviewUrl } = this.state;

        return (
            <React.Fragment>
                {this.state.shareModal && (
                    <div className='element-modal'>
                        <div className='content'>
                            <button className='close-btn' onClick={this.toggleSharePanel}><i className="fa-solid fa-x"></i></button>
                            <div className='container'>
                                <h1>Upload Image</h1>
                            </div>
                            {imagePreviewUrl ?
                                <Cropper
                                    style={{ height: 400, width: "100%" }}
                                    ref={this.cropperRef}
                                    src={imagePreviewUrl}
                                    aspectRatio={1} // Square shape
                                    guides={true}
                                    dragMode="crop"
                                />
                                :
                                <p>Choose your organisation logo</p>
                            }
                            <div className='container'>
                                <input type='file' placeholder='Email' accept=".jpg,.png,.jpeg" onChange={this.handleImageChange} />
                                <button type="button" onClick={this.handleSubmit}>Upload</button>
                            </div>
                        </div>
                    </div>
                )}
                {this.state.passAuthModal && (
                    <div className='element-modal transfer-modal'>
                        <div className='content'>
                            <button className='close-btn' onClick={this.togglePassAuthModal}><i className="fa-solid fa-x"></i></button>
                            <div className='container'>
                                <h1>Re-enter your password</h1>
                                <p>Enter your password to continue</p>
                            </div>
                            <form className='container' onKeyPress={this.handleKeyPress}>
                                <input type='text' name='email' autoComplete='username' value={this.props.value} hidden />
                                <input type='password' placeholder='Password' autoComplete='new-password' value={this.state.pass} onChange={this.updatePass} required />
                                <button type="button" onClick={this.authenticateLogin}>Authenticate <i className="fa-solid fa-arrow-right"></i></button>
                            </form>
                            <p className='reset'>Forgot password? <button onClick={this.changePassword}>Reset password</button></p>
                        </div>
                    </div>
                )}
                {this.state.transferModal && (
                    <div className='element-modal transfer-modal'>
                        <div className='content'>
                            <button className='close-btn' onClick={this.toggleTransferModal}><i className="fa-solid fa-x"></i></button>
                            <div className='container'>
                                <h1>Transfer Organisation Ownership To</h1>
                            </div>
                            <form className='container'>
                                <input type='text' placeholder='Email' value={this.state.transferTo} onChange={this.updateTransferToEmail} required />
                                {this.state.transferToValid && (<p className='email-validated'><i className="fa-solid fa-circle-check"></i> Email validated</p>)}
                                <p>Transferring organisation ownership will move all admin privilages to the above account. Current account will be removed from organisation. This action cannot be reverted.</p>
                                <button type="button" onClick={this.transferAdminPermanently}>Transfer <i className="fa-solid fa-arrow-right"></i></button>
                            </form>
                        </div>
                    </div>
                )}
                {this.state.subOrganisationModal && (
                    <div className='element-modal transfer-modal'>
                        <div className='content'>
                            <button className='close-btn' onClick={this.toggleSubOrganisationModal}><i className="fa-solid fa-x"></i></button>
                            <div className='container'>
                                <h1>Add New Sub Organisation</h1>
                            </div>
                            <form className='container'>
                                <input type='text' placeholder='Sub Organisation Name' value={this.state.subOrganisationName} onChange={this.updateSubOrganisationName} required maxLength="60" />
                                {this.state.characterRemaining !== 0 && (<p className='small-gray'>{this.state.characterRemaining} characters left</p>)}
                                {this.state.subOrganisationName.length > 2 && (<p className='small-font'>Your new sub organisation will be displayed as <br></br> <b>{this.state.subOrganisationName}, {this.state.org_name}.</b></p>)}
                                <button type="button" onClick={this.addSubOrganisation}>Create <i className="fa-solid fa-arrow-right"></i></button>
                            </form>
                        </div>
                    </div>
                )}
                {this.state.tutorialVideo && (
                    <div className='tutorial-video-modal'>
                        <video src={tutorialVideo} muted={false} onEnded={this.skipVideoTutorial} controls poster={videoThumbnail} />
                        <div className='skip-btn'>
                            <button onClick={this.skipVideoTutorial}>Skip <i className="fa-solid fa-forward"></i></button>
                        </div>
                    </div>
                )}
                <div className='profile-icon-edit'>
                    <img key={this.state.refreshKey} src={this.state.profileImageIcon} alt="user icon"></img>
                    {!this.state.noAccess && (<button className='edit-btn' onClick={this.toggleSharePanel}><i className="fa-solid fa-pen"></i></button>)}
                </div>
                {!this.state.newHere && !this.state.noAccess && (
                    <React.Fragment>
                        <h1><span>Welcome, </span>{this.state.org_name}</h1>
                        <p>Manage your organisation info and its users from here.</p>
                        <div className='btn-wrapper'>
                            <button onClick={this.openVideoTutorial}>Watch Video <i className="fa-solid fa-play"></i></button>
                            <button className='secondary' onClick={() => window.location.href = '/tutorial'}>Interactive Tutorial <i className="fa-solid fa-shapes"></i></button>
                        </div>
                    </React.Fragment>
                )}
                {this.state.noAccess ?
                    this.state.sectionLoaded ?
                        <React.Fragment>
                            <h1><span>Welcome</span></h1>
                            <p className='small-font'>Only admin of this organisation has access to this settings.</p>
                            <p className='small-font'>Request admin for any settings changes.</p>
                            <p className='small-font'>Admin email: <b>{this.state.organisationAdmin}</b></p>
                            <div className='btn-wrapper'>
                                <button onClick={this.openVideoTutorial}>Watch Video <i className="fa-solid fa-play"></i></button>
                                <button className='secondary' onClick={() => window.location.href = '/tutorial'}>Interactive Tutorial <i className="fa-solid fa-shapes"></i></button>
                            </div>
                            <div className='account-container'>
                                <div className='container'>
                                    <h2>Manage</h2>
                                    <p className='small-font'>Manage your organisation account.</p>
                                    <div className='column-container'>
                                        <p>Leave Organisation</p>
                                        <div className='row-container'>
                                            <p className='small-font'>Once done, you will be removed from organisation.<br></br>Please be certain.</p>
                                            <button className='red-btn' onClick={this.leaveOrganisation}>Leave Organisation</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                        :
                        <p>Loading...</p>
                    :
                    <div className='account-container'>
                        {this.state.verified !== 'verified' && (
                            <div className='container full highlight' style={{ backgroundColor: '#ffd4d4' }}>
                                <h2>Attention!</h2>
                                <button className='secondary-btn' onClick={() => window.location.href = './account?selection=verification'}>Become a verfied organisation <i className="fa-solid fa-arrow-right-long"></i></button>
                                <p className='small-font'>Your organisation is not verified, <b className='red'>The certificates you distribute lacks credibility.</b> <a className='link' href='./account?selection=verification'>Learn More.</a></p>
                            </div>
                        )}
                        {this.state.newHere && (
                            <div className='container full organiser-account-tutorial'>
                                <h2>Welcome, <span>{this.state.org_name}</span> !</h2>
                                <button className='secondary-btn' onClick={() => window.location.href = '/tutorial'}>Create Certificate <i className="fa-solid fa-arrow-right-long"></i></button>
                                <p className='small-font'>Learn how to use pronetin</p>
                                <button className='play-tutorial-btn' onClick={this.openVideoTutorial}>Watch Video <i className="fa-solid fa-play"></i></button>
                            </div>
                        )}
                        <div className='container full'>
                            <h2>Manage Organisers</h2>
                            <p className='small-font'>Remove users from your organisation.</p>
                            <div className='grid-wrap'>
                                {this.state.organisers ?
                                    this.state.organisers !== null && this.state.organisers.length > 0 && (this.state.organisers.map((element, index) =>
                                        <div className='column-container' key={index}>
                                            <p>{element}</p>
                                            <button className='secondary-btn red' onClick={() => this.deleteOrganiser(element)}>Remove <i className="fa-solid fa-trash"></i></button>
                                        </div>
                                    ))
                                    :
                                    <div className='column-container'>
                                        <p className='small-font'>You do not have any users in your organisation</p>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='container' id='organisers'>
                            <h2>Pending Requests</h2>
                            <p className='small-font'>Accept or Reject users from your organisation.</p>
                            <div className='column-container'>
                                <p>Invite Organiser</p>
                                <div className='row-container'>
                                    <input type='text' placeholder='Email Id' value={this.state.inviteeEmail} onChange={this.changeInviteeEmail} />
                                    <button onClick={this.requestInvitee}>Invite</button>
                                </div>
                            </div>
                            <div className='grid-wrap'>
                                {this.state.join_requests && this.state.join_requests.length > 0 ?
                                    this.state.join_requests.map((element, index) => (
                                        <div className='column-container' key={index}>
                                            <p>{element}</p>
                                            <div className='action-btns'>
                                                <button className='accept' onClick={() => this.acceptJoinRequest(element)}><i className="fa-solid fa-circle-check"></i></button>
                                                <button className='reject' onClick={() => this.rejectJoinRequest(element)}><i className="fa-solid fa-circle-xmark"></i></button>
                                            </div>
                                        </div>
                                    ))
                                    :
                                    <div className='column-container'>
                                        <p className='small-font'>You do not have any join requests</p>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='container full' id='suborg'>
                            <h2>Manage Sub Organisations</h2>
                            <p className='small-font'>Add / Remove sub organisations.</p>
                            <div className='grid-wrap'>
                                {this.state.subOrganisations ?
                                    this.state.subOrganisations !== null && this.state.subOrganisations.length > 0 && (this.state.subOrganisations.map((element, index) =>
                                        <div className='column-container' key={index}>
                                            <p>{element}</p>
                                            <button className='secondary-btn red' onClick={() => this.deleteSubOrganisation(element)}>Remove <i className="fa-solid fa-trash"></i></button>
                                        </div>
                                    ))
                                    :
                                    <div className='column-container'>
                                        <p className='small-font'>You do not have any sub organisation</p>
                                    </div>
                                }
                            </div>
                            <button className='secondary-btn' onClick={this.toggleSubOrganisationModal}>Create Sub Organisation +</button>
                        </div>
                        <div className='container'>
                            <h2>Basic Info</h2>
                            <p className='small-font'>Some info may be visible to other people.</p>
                            <div className='column-container'>
                                <p>Organisation Name</p>
                                <div className='row-container'>
                                    <input className='gray' type='text' placeholder='Organisation Name' name='name' value={this.state.org_name} onChange={this.changeName} maxLength="120"></input>
                                    <button onClick={this.requestNameChange}>Request Change</button>
                                </div>
                                <p className='info'>Name can be only changed once in 90 days</p>
                            </div>
                            <div className='column-container'>
                                <p>Sector</p>
                                <div className='row-container'>
                                    <input type='text' placeholder='Sector' name='designation' value={this.state.sector} onChange={this.changeSector}></input>
                                </div>
                            </div>
                            <div className='column-container'>
                                <p>Registered Domain</p>
                                <div className='row-container'>
                                    <input className='gray' type='text' placeholder='domain.com' value={this.state.domain} name='domain' readOnly></input>
                                </div>
                            </div>
                            <div className='fg-1'></div>
                            <button onClick={this.updateProfile}><i className="fa-regular fa-floppy-disk"></i> Save</button>
                        </div>
                        <div className='container'>
                            <h2>Contact Info</h2>
                            <p className='small-font'>This info may be used by your recepients to contact you.</p>
                            <div className='column-container'>
                                <p>Address</p>
                                <div className='row-container'>
                                    <input type='text' placeholder='Address Line 1' name='adl1' value={this.state.adl1} onChange={this.changeAdl1} maxLength="120"></input>
                                    <input type='text' placeholder='Address Line 2' name='adl2' value={this.state.adl2} onChange={this.changeAdl2} maxLength="120"></input>
                                </div>
                            </div>
                            <div className='column-container'>
                                <div className='row-container'>
                                    <input type='text' placeholder='Country' name='country' value={this.state.country} onChange={this.changeCountry} maxLength="40"></input>
                                    <input type='text' placeholder='Pin Code' name='pincode' value={this.state.pincode} onChange={this.changePincode} maxLength="20"></input>
                                </div>
                            </div>
                            <div className='column-container'>
                                <p>Organisation Contact</p>
                                <div className='row-container'>
                                    <select
                                        value={this.state.selectedCountryCode}
                                        onChange={this.handleCountryCodeChange}
                                    >
                                        <option value="" disabled>Select Country Code</option>
                                        {countryCodes.map((country) => (
                                            <option key={country.code} value={country.code}>
                                                {`${country.name} (${country.code})`}
                                            </option>
                                        ))}
                                    </select>
                                    <input type='text' placeholder='Phone' name='phone' value={this.state.phone} onChange={this.changePhone}></input>
                                </div>
                            </div>
                            <div className='column-container'>
                                <p>Organisation Social</p>
                                <div className='row-container'>
                                    <input type='text' placeholder='Linkedin URL' name='linkedin' value={this.state.linkedin} onChange={this.changeLinkedin}></input>
                                </div>
                            </div>
                            <div className='fg-1'></div>
                            <button onClick={this.updateProfile}><i className="fa-regular fa-floppy-disk"></i> Save</button>
                        </div>
                        <div className='container'>
                            <h2>About Organisation</h2>
                            <p className='small-font'>Info about your organisation.</p>
                            <div className='column-container'>
                                <p>Plan</p>
                                <p className='small-font'>{this.state.running_plan.replace("-y", "")} plan</p>
                                <p className='small-font'>{this.state.running_plan.includes("-y") ? "Yearly Subscription" : "Monthly Subscription"}</p>
                                <button className='secondary-btn' onClick={() => window.location.href = './premium'}><i className="fa-regular fa-circle-up"></i> Upgrade</button>
                            </div>
                            <h2>Manage</h2>
                            <p className='small-font'>Manage your organisation account.</p>
                            <div className='column-container'>
                                <p>Delete Organisation</p>
                                <div className='row-container'>
                                    <p className='small-font'>Once deleted, it will be gone forever.<br></br>Please be certain.</p>
                                    {this.state.passAuth ?
                                        <button className='red-btn' onClick={this.deleteOrganisationPermanently}>Delete Account</button>
                                        :
                                        <button className='red-btn' onClick={() => this.togglePassAuthModal('delete')}>Delete Account</button>
                                    }
                                </div>
                            </div>

                            <div className='column-container'>
                                <p>Transfer Organisation</p>
                                <div className='row-container'>
                                    <p className='small-font'>Once transfered, it will only be linked to new account.<br></br>Please be certain.</p>
                                    {this.state.passAuth ?
                                        <button className='red-btn' onClick={this.toggleTransferModal}>Transfer Account</button>
                                        :
                                        <button className='red-btn' onClick={() => this.togglePassAuthModal('transfer')}>Transfer Account</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                }
                {
                    this.state.warning_action.message !== "" ? <WarningAction data={this.state.warning_action} fetchWarningActionState={this.fetchWarningActionState} resumeFunction={this.resumeFunction} /> : ""
                }
                {
                    this.state.progress_loader && (<ProgressLoader />)
                }
            </React.Fragment>
        );
    }
}

export default DataComponent;