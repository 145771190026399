import React from 'react';
import './css/element.css';
import axios from 'axios';

import Warning from './warning';
import ViewSignee from './viewer/signee';

import WarningAction from './warning_action';
import ProgressLoader from './progress_loader';

class ProfileComponent extends React.Component {
    constructor(props) {
        super(props);
        this.fetchWarningState.bind(this);
        this.state = {
            document_count: 0,
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            warning_action: {
                message: "",
                subtext: "",
                type: "warning",
                proceed_action: false,
                proceed_function: false,
                back_action: false,
                close: true,
            },
            approval_history: [],
            approvalHistory_batch: 3,
            approvalHistory_page: 1,
            approvalHistory_pageLimit: 0,
            templatePreviewModal: false,
            previewImage: null,
            elements: [],
            progress_loader: false,
        };
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    fetchWarningActionState = (data) => {
        this.setState({
            warning_action: {
                message: data,
            }
        })
    }

    componentDidMount = () => {
        this.getApprovalHistory(1);
    }

    getApprovalHistory = (offset) => {
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'get_approvals_list', {
            params: {
                email: this.props.email,
                limit: this.state.approvalHistory_batch * offset,
            },
        }).then(res => {
            if (res.data.approval_list) {
                this.setState({
                    approval_list: res.data.approval_list,
                    uniqueEvents: [...new Set(res.data.approval_list.map(cert => JSON.parse(cert).event))],
                    approval_history: res.data.approval_history,
                    approval_history_count: parseInt(res.data.approval_history_count),
                    approvalHistory_pageLimit: Math.ceil(parseInt(res.data.approval_history_count) / this.state.approvalHistory_batch),
                })
            } else if (res.data.approval_history) {
                this.setState({
                    approval_history: res.data.approval_history,
                    approval_history_count: parseInt(res.data.approval_history_count),
                    approvalHistory_pageLimit: Math.ceil(parseInt(res.data.approval_history_count) / this.state.approvalHistory_batch),
                })
            }
        });
    }

    // checkEligibility = () => {
    //     if (this.props.user_type !== "organisation") {
    //         this.setState({
    //             warning: {
    //                 message: "Sorry, You need an organisation account to issue certificates.",
    //                 type: "warning",
    //                 learnmore: "create",
    //                 autoclose: false,
    //             }
    //         })
    //     } else {
    //         window.location = "/issue";
    //     }
    // }

    approvalHistoryMore = () => {
        this.setState({
            approvalHistory_page: this.state.approvalHistory_page < this.state.approvalHistory_pageLimit ? this.state.approvalHistory_page + 1 : this.state.approvalHistory_page,
        }, () => {
            this.getApprovalHistory(this.state.approvalHistory_page);
        })
    }

    openTemplatePreview = (e, batch_id) => {
        axios.post(this.props.node_server_axios + 'get_batch_data', {
            params: {
                batchId: batch_id,
            },
        }).then(res => {
            this.setState({
                elements: res.data.elements,
            })
        }).catch(err => { console.log(err) })
        this.setState({
            templatePreviewModal: true,
            previewImage: e.target.src,
        })
    }

    closeTemplatePreview = (e) => {
        if (e.target.classList.contains('batchcert-preview-modal')) {
            this.setState({
                templatePreviewModal: false,
                elements: [],
            })
        }
    }

    updateScale = () => {
        const divWidth = this.divRef.offsetWidth;
        const screenWidth = window.innerWidth - 40;
        const scaleFactor = screenWidth / divWidth;
        if (scaleFactor < 1) {
            this.setState({ scaleFactor });
        }
    };

    render() {
        const { scaleFactor } = this.state;

        return (
            <React.Fragment>
                <div className="element">
                    {this.state.templatePreviewModal && (
                        <div className='batchcert-preview-modal' onClick={this.closeTemplatePreview}>
                            <div className='preview-rel' ref={ref => (this.divRef = ref)} style={{ transform: `scale(${scaleFactor})` }} onLoad={this.updateScale}>
                                <img src={this.state.previewImage} alt='certificate template' />
                                {
                                    this.state.elements.length > 0 ?
                                        this.state.elements.map((element, index) => {
                                            if (element.type === "signature") {
                                                return <ViewSignee element_id={"view-" + index} key={"view-" + element.timeStamp + "-" + index} data={element} node_server_axios={this.state.signeeStatus ? this.props.node_server_axios : undefined} batch_id={this.props.batchId} />
                                            } else {
                                                return null;
                                            }
                                        })
                                        :
                                        <div className='sign-loader'>
                                            Fetching Data...
                                        </div>
                                }
                            </div>
                        </div>
                    )}
                    <div className="container">
                        <div className='header'>
                            <div className='container-left'>
                                <h1>Approval History<label>History of signed certificates</label></h1>
                            </div>
                            {/* <button className='btn-issue' onClick={this.checkEligibility}><i className="fa-solid fa-stamp"></i> Create Certificate</button> */}
                        </div>
                        <div className='tiles marketplace'>
                            <div className='approval-wrapper full-width'>
                                {this.state.approval_history.map((element, index) => {
                                    return (
                                        <div className='container' key={index}>
                                            {/* <div className='approval-icon'>
                                                <i className="fa-solid fa-clipboard-check"></i>
                                            </div> */}
                                            <div className='certificate-preview'>
                                                <img src={"https://pronetin-new.s3.amazonaws.com/batch/" + encodeURIComponent(element.organisation) + "/" + encodeURIComponent(element.preview.replace('.png', '_preview.png'))} alt="Batch Deleted by Admin / Organiser" onClick={(e) => this.openTemplatePreview(e, element.id)} />
                                            </div>
                                            <div>
                                                <h2>{element.event}</h2>
                                                <p>Date : {element.date}</p>
                                                <h3>Title : {element.title}</h3>
                                                <p>{element.org_name}</p>
                                            </div>
                                            <div className='flex-grow'></div>
                                            <div className='actions'>
                                                {element.status === 'signed' ?
                                                    <div className="status-btn signed"><i className="fa-regular fa-circle-check"></i> Signed</div>
                                                    :
                                                    <div className="status-btn rejected"><i className="fa-regular fa-circle-xmark"></i> Rejected</div>
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                                }
                                {this.state.approval_history.length > 0 && this.state.approvalHistory_page < this.state.approvalHistory_pageLimit && (
                                    <div className="next-prev-btns">
                                        <button onClick={this.approvalHistoryMore}>More <i className="fa-solid fa-arrow-down"></i></button>
                                    </div>
                                )}
                            </div>
                            {
                                this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                            }
                            {
                                this.state.warning_action.message !== "" ? <WarningAction data={this.state.warning_action} fetchWarningActionState={this.fetchWarningActionState} resumeFunction={this.resumeFunction} /> : ""
                            }
                        </div>
                    </div>
                </div>
                {
                    this.state.progress_loader && (<ProgressLoader />)
                }
            </React.Fragment>
        );
    }
}

export default ProfileComponent;